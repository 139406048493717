import Container from '../Container'

const stats = [
  {
    label: 'Users protected by Sety',
    value: '13,000+'
  },
  {
    label: 'Panic intiated by users',
    value: '5,000+'
  },
  {
    label: 'Certified Responders',
    value: '25,000+'
  },
  {
    label: 'Response time',
    value: '<10mins'
  },
];

export default function Stats() {
  return (
    <section className="py-40">
      <Container className="text-center px-2">
        <h2 className="text-4xl lg:text-6xl font-bold mb-4">Sety by the numbers</h2>
        <p className="text-base text-[#555] lg:text-xl mb-10">Sety makes sure that you get home to your loved ones in one piece. Everyday.</p>
        <div className="bg-primary mt-12 py-10 flex md:flex-row flex-wrap rounded-xl text-white">
          {stats.map((stat, index) => (
            <div key={index} className="w-1/2 md:w-1/4 flex flex-col items-center my-4 px-2 pt-6 pb-6 md:pb-0 md:pt-10 border-r border-border last-of-type:border-none">
              <h4 className="text-3xl lg:text-5xl font-bold font-moderat mb-4">{stat.value}</h4>
              <span className="block w-4/5 lg:w-3/5 text-base lg:text-lg text-gray-300">
                {stat.label}
              </span>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
