import React from 'react'

export default function Logo(props) {
  return (
    <svg
      width={116}
      height={39}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.861 21.822c0 1.059-.777 2.142-2.851 2.142-1.588 0-2.396-1.215-3.662-2.142l-.047-.04a.531.531 0 0 0-.619-.062c-.614.353-1.775 1.026-2.466 1.427a.558.558 0 0 0-.162.832c1.868 2.32 4.118 3.923 7.02 3.923 4.472 0 7.699-3.062 7.699-6.139 0-2.943-2.514-5.26-6.586-5.615-2.668-.233-2.992-1.217-2.992-2.028 0-.811.843-1.737 2.01-1.737 1.24 0 2.17.797 2.502 1.757.055.16.21.276.397.276h4.05c.262 0 .464-.216.409-.444-.762-3.075-3.878-5.411-7.488-5.411-3.61 0-6.306 3.043-6.306 5.707 0 2.89 1.532 4.756 5.593 5.295 2.657.277 3.5 1.216 3.5 2.259Z"
        fill="#5341C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.783 21.1c.122-.204.282-.142.518.01l2.59 1.705c.142.143.13.331.012.528-1.274 2.138-4.1 4.354-7.664 4.533-6.255.18-10.147-4.756-10.147-9.58 0-5.232 4.131-9.7 9.776-9.7 5.215 0 9.495 4.417 9.495 9.407 0 .107.002.218.004.333.008.362.016.754-.047 1.09a.378.378 0 0 1-.371.312h-13.85a.383.383 0 0 0-.36.49c.611 1.701 2.462 3.517 5.118 3.517 2.491-.09 4.32-1.628 4.926-2.645Zm-.452-4.693h-9.073c-.295 0-.488-.267-.377-.508.831-1.777 2.785-3.087 5-3.087 2.053 0 4.133 1.354 4.834 3.104.095.238-.096.491-.384.491Z"
        fill="#5341C4"
      />
      <path
        d="M91.518 21.094c.002 1.712 1.06 2.964 2.626 2.964.786-.041 1.126-.126 1.502-.21.22-.05.488.063.488.523l.034 2.648a.394.394 0 0 1-.201.351c-.812.436-1.953.683-3.125.683-3.894 0-6.324-2.236-6.324-6.651l.126-7.87a.399.399 0 0 0-.4-.398h-3.117a.399.399 0 0 1-.398-.4V9.19c0-.22.179-.399.398-.399h3.118c.22 0 .398-.179.398-.399V6.06c0-.192-.071-.541.323-.84l4.2-1.381c.262-.132.476.14.476.391v4.162c0 .222.18.4.399.4h3.636c.22 0 .398.179.398.398v3.545c0 .221-.179.399-.398.399h-3.633a.399.399 0 0 0-.4.399l-.124 7.561h-.002ZM104.144 27.759l-3.038 7.746 2.154-.022a4.439 4.439 0 0 0 4.12-2.908l8.542-23.249a.399.399 0 0 0-.375-.536h-4.34c-.17 0-.322.11-.378.27l-3.779 11.112a.399.399 0 0 1-.756-.001l-3.713-11.107a.397.397 0 0 0-.378-.272h-4.338a.4.4 0 0 0-.375.536l6.657 18.147a.397.397 0 0 1-.003.284Z"
        fill="#5341C4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.535 37.274A19.429 19.429 0 0 1 19.502 39c-10.77 0-19.5-8.73-19.5-19.5S8.732 0 19.502 0c2.41 0 4.717.437 6.847 1.236l.762.334a1.23 1.23 0 0 1-.783 2.323 16.883 16.883 0 0 0-.66-.272l-.01-.005a16.988 16.988 0 0 0-6.156-1.146c-9.406 0-17.03 7.624-17.03 17.03 0 9.406 7.624 17.03 17.03 17.03 2.555 0 4.98-.563 7.155-1.571a1.238 1.238 0 0 1 .931 2.29l-.053.025Zm5.845-4.075A19.438 19.438 0 0 0 39.002 19.5c0-4.965-1.855-9.496-4.91-12.938l-.534-.543a1.218 1.218 0 0 0-1.776 1.666l.326.365c.059.064.117.129.174.194l.058.065A16.965 16.965 0 0 1 36.531 19.5c0 4.56-1.793 8.703-4.712 11.76l-.21.26c-.391.48-.36 1.18.072 1.624a1.222 1.222 0 0 0 1.698.055Z"
        fill="#B8AEF3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.755 19.5c0 7.871-6.38 14.252-14.252 14.252-7.871 0-14.252-6.38-14.252-14.252 0-2.084.447-4.063 1.25-5.847a1.116 1.116 0 0 1 1.282-.543l.054.015c.679.19 1.043.927.781 1.58l-.07.177c-.6 1.42-.93 2.98-.93 4.618 0 6.564 5.32 11.886 11.884 11.886S31.388 26.064 31.388 19.5c0-6.564-5.322-11.885-11.886-11.885-2.732 0-5.248.922-7.256 2.471l.001-.002-.58.459a1.16 1.16 0 0 1-1.506-1.761l.48-.444a14.191 14.191 0 0 1 8.862-3.09c7.871 0 14.252 6.38 14.252 14.252Z"
        fill="#9489DA"
      />
      <path
        d="M19.395 28.326a8.935 8.935 0 1 0 0-17.87 8.935 8.935 0 0 0 0 17.87Z"
        fill="#5341C4"
      />
    </svg>
  )
}
