import { useRef } from 'react';
import { motion, useScroll } from "framer-motion"
import { Header, Footer, LeftHero, Stats, Partners, SoS } from '../components/common';
import { Overview, Communities, Join } from '../components/business';
import background from "../assets/images/business-hero-img.jpg";

export default function Business() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div ref={scrollRef} style={{ overflow: "scroll" }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-blue h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main>
        <LeftHero
          banner={background}
          title="Emergency response for your customers, staff and assets"
          subtitle="Our safety network are equipped with life-saving incident data to help keep your users, customers, and assets safe."
        />
        <Stats />
        <Overview />
        <Communities />
        <Partners />
        <SoS />
        <Join />
      </main>
      <Footer />
    </div>  
  )
}
