import { useRef } from 'react';
import { motion, useScroll } from "framer-motion"
import { IncidentReport, Mission } from '../components/home';
import { Header, Footer, LeftHero, Stats, SoS, Platform, Partners } from '../components/common';
import background from "../assets/images/home-hero-img.webp";


function Home() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div ref={scrollRef} style={{ overflow: "scroll", backgroundColor: '#FFFFFF' }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-red h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main>
        <LeftHero
          banner={background}
          title="Delivering safety using technology"
          subtitle="Sety offers tailored on-demand safety and security solutions for businesses, individuals, and families. Our commitment to proactive measures and cutting-edge technology ensures comprehensive protection, making Sety your reliable partner for peace of mind."
        />
        <IncidentReport />
        <Partners />
        <Platform />
        <SoS />
        <Mission />
        <Stats />
      </main>
      <Footer />
    </div>
  );
}

export default Home;
