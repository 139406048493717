import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import clsx from 'clsx'
const itemVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.75 } },
    hidden: { opacity: 0, scale: 0 }
};


export default function ListItem({ children, classNames }) {
    const control = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);
    return (
        <motion.li
            className={clsx("list-none", classNames)}
            ref={ref}
            variants={itemVariant}
            initial="hidden"
            animate={control}
        >
            {children}
        </motion.li>
    )
}
