import Container from '../Container';
import { ButtonLink } from '../Button';


export default function Join() {
  return (
    <section className="py-20">
      <Container className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 mb-12 lg:mb-0">
          <div role="status" className="flex flex-row">
            <span className="sr-only">Loading...</span>
            <img src={require('../../assets/images/safe-1.png')} alt="" className='bg-gray-400 w-7/12 md:w-44 h-54 lg:w-64 lg:h-64 rounded-lg' />
            <div className="ml-6 lg:ml-10 w-5/12">
              <img src={require('../../assets/images/safe-2.png')} alt="" className='bg-gray-300 w-full md:w-40 h-40 lg:w-44 lg:h-44 rounded-lg mb-5' />
              <img src={require('../../assets/images/safe-3.png')} alt="" className='mt-8 w-20 h-20 lg:w-25 lg:h-25 rounded-lg' />
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <h2 className="text-4xl lg:text-6xl font-extrabold w-full lg:w-4/5 mb-6">Partner with sety to save lives</h2>
          <p className="text-lg lg:text-xl text-[#4F4F4F] lg:w-4/5 mb-6">To learn more how Sety can provide safety to your users, employees, customers, and communities better, then get in touch with us.</p>
          <div className="flex space-x-4">
            <ButtonLink href="mailto:info@sety.io?subject=Enquiry on joining Sety">Get started</ButtonLink>
            <ButtonLink href="mailto:info@sety.io?subject=Enquiry on joining Sety" color="secondary">Get in touch</ButtonLink>
          </div>
        </div>
      </Container>
    </section>
  )
}
