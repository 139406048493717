import React, { useRef } from 'react'
import { motion, useScroll } from "framer-motion"
import { Header, Footer } from '../components/common';

export default function Terms() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div className="" ref={scrollRef} style={{ overflow: "scroll" }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-emphatic h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main className="" data-spy="scroll" data-target="#navbar-example2" data-offset="0">
        <section className="mx-auto max-w-7xl px-4 md:px-8 lg:px-10 flex flex-col lg:flex-row items-center mt-24" id="Services">
          <div className="container">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold">Terms<span className="text-primary"> &amp; </span>conditions</h2>
            </div>

            <div className="pb-12 page-content">
              <h3>ABOUT THIS POLICY</h3>
              <br>
              </br>
              <p>

                The corporate information, records and data held by SETY is important to how we conduct business and manage employees.<br />
                There are legal and regulatory requirements for us to retain certain data, usually for a specified amount of time. We also retain data to help our business operate and to have information available when we need it. However, we do not need to retain all data indefinitely, and retaining data can expose us to risk as well as be a cost to our business.<br />
                This Data Retention Policy explains our requirements to retain data and to dispose of data and provides guidance on appropriate data handling and disposal. <br />
                Failure to comply with this policy can expose us to fines and penalties, adverse publicity, difficulties in providing evidence when we need it and in running our business.<br />
                This policy does not form part of any employee's contract of employment and we may amend it at any time.</p><br />
              <h3>SCOPE OF POLICY</h3><br />
              <p>
                This policy covers all data that we hold or have control over. This includes physical data such as hard copy documents, contracts, notebooks, letters and invoices. It also includes electronic data such as emails, electronic documents, audio and video recordings and CCTV recordings. It applies to both personal data and non-personal data. In this policy we refer to this information and these records collectively as "data".<br />
                This policy covers data that is held by third parties on our behalf, for example cloud storage providers or offsite records storage. It also covers data that belongs to us but is held by employees on personal devices  <br />
                This policy explains the differences between our formal or official records, disposable information, confidential information belonging to others, personal data and non-personal data. It also gives guidance on how we classify our data.<br />
                This policy applies to all business units and functions of SETY
              </p>
              <br />
              <h3>GUIDING PRINCIPLES</h3>
              <br />
              <p>

                Through this policy, and our data retention practices, we aim to meet the following commitments:<br />
                compliance with legal and regulatory requirements to retain data.<br />
                compliance with our data protection obligations, in particular to keep personal data no longer than is necessary for the purposes for which it is processed (storage limitation principle).<br />
                handling, storage and disposal of data responsibly and securely.<br />
                collection and retention of data where we need this to operate our business effectively, not without good business reason.<br />
                allocation of appropriate resources, roles and responsibilities to data retention.<br />
                regular reminders to employees of their data retention responsibilities.<br />
                regular monitoring and audit compliance with this policy and updating this policy when required.<br />
              </p>
              <br />
              <h3>ROLES AND RESPONSIBILITIES</h3>
              <p>

                <b>Responsibility of all employees</b>. We aim to comply with the laws, rules, and regulations that govern our organisation and with recognised compliance good practices. All employees must comply with this policy, the Record Retention Schedule, any communications suspending data disposal and any specific instructions from Management. Failure to do so may subject us, our employees, and contractors to serious civil and/or criminal liability. An employee's failure to comply with this policy may result in disciplinary sanctions, including suspension or termination. It is therefore the responsibility of everyone to understand and comply with this policy.<br />
                We may designate a Records Management Officer to be responsible for identifying the data that we must or should retain, and determining, in collaboration with the Data Protection Officer, the proper period of retention. To also arrange for the proper storage and retrieval of data, coordinating with outside vendors where appropriate. Additionally, the Records Management Officer also handles the destruction of records whose retention period has expired.<br />
                The Records Management Officer or staff so designated is responsible for:<br />
                Administering the data management programme;<br />
                Helping department heads implement the data management programme and related best practices;<br />
                Planning, developing, and prescribing data disposal policies, systems, standards, and procedures; and<br />
                Providing guidance, training, monitoring and updating in relation to this policy.<br />
                <b>Data Protection Officer</b>. Our Data Protection Officer (DPO) is responsible for advising on and monitoring our compliance with data protection laws which regulate personal data. Our DPO works with our Records Management Officer or designated staff on the retention requirements for personal data and on monitoring compliance with this policy in relation to personal data. <br />  </p>
              <br />
              <h3>
                TYPES OF DATA AND DATA CLASSIFICATIONS
              </h3>
              <br />
              <p>

                Formal or official records. Certain data is more important to us and is therefore listed in the Record Retention Schedule. This may be because we have a legal requirement to retain it, or because we may need it as evidence of our transactions, or because it is important to the running of our business. Please see paragraph 6.1 below for more information on retention periods for this type of data. <br />
                Disposable information. Disposable information consists of data that may be discarded or deleted at the discretion of the user once it has served its temporary useful purpose and/or data that may be safely destroyed because it is not a formal or official record as defined by this policy and the Record Retention Schedule. Examples may include:<br />
                Duplicates of originals that have not been annotated.<br />
                Preliminary drafts of letters, memoranda, reports, worksheets, and informal notes that do not represent significant steps or decisions in the preparation of an official record.<br />
                Books, periodicals, manuals, training binders, and other printed materials obtained from sources outside of SETY and retained primarily for reference purposes.<br />
                Spam and junk mail.<br />
                Please see paragraph 6.2 below for more information on how to determine retention periods for this type of data.<br />
                Personal data. Both formal or official records and disposable information may contain personal data; that is, data that identifies individuals. Data protection laws require us to retain personal data for no longer than is necessary for the purposes for which it is processed (principle of storage limitation). See paragraph 6.3 below for more information on this.<br />
                Confidential information belonging to others. Any confidential information that an employee may have obtained from a source outside of SETY such as a previous employer, must not, so long as such information remains confidential, be disclosed to or used by us. Unsolicited confidential information submitted to us should be refused, returned to the sender where possible, and deleted, if received via the internet. <br />
              </p>
              <br />
              <h3>RETENTION PERIODS </h3>
              <br />
              <p>

                Formal or official records. Any data that is part of any of the categories listed in the Record Retention Schedule contained in the annex to this policy, must be retained for the amount of time indicated in the Record Retention Schedule. A record must not be retained beyond the period indicated in the Record Retention Schedule, unless a valid business reason (or notice to preserve documents for contemplated litigation or other special situation) calls for its continued retention. If you are unsure whether to retain a certain record, contact the Records Management Officer or the Data Protection Officer<br />
                Disposable information. The Record Retention Schedule will not set out retention periods for disposable information. This type of data should only be retained as long as it is needed for business purposes. Once it no longer has any business purpose or value it should be securely disposed of.<br />
                Personal data. As explained above, data protection laws require us to retain personal data for no longer than is necessary for the purposes for which it is processed. <br />
                What to do if data is not listed in the Record Retention Schedule. If data is not listed in the Record Retention Schedule, it is likely that it should be classed as disposable information. However, if you consider that there is an omission in the Record Retention Schedule, or if you are unsure, please contact the Records Management Officer OR Data Protection Officer.<br />
              </p>
              <br />
              <h3>STORAGE, BACK-UP AND DISPOSAL OF DATA</h3>
              <br />
              <p>

                Storage. Our data must be stored in a safe, secure, and accessible manner. Any documents and financial files that are essential to our business operations during an emergency must be duplicated and/or backed up at least once per week and maintained off site. <br />
                Destruction. Our Records Management Officer (RMO) is responsible for the continuing process of identifying the data that has met its required retention period and supervising its destruction. The destruction of confidential, financial, and employee-related hard copy data must be conducted by shredding if possible. Non-confidential data may be destroyed by recycling. The destruction of electronic data must be coordinated with the IT department.<br />
                The destruction of data must stop immediately upon notification from any department that preservation of documents for contemplated litigation is required. Destruction may begin again once the department lifts the requirement for preservation.<br />
              </p>
              <br />
              <h3>SPECIAL CIRCUMSTANCES</h3><br />
              <p>

                Preservation of documents for contemplated litigation and other special situations. We require all employees to comply fully with our Record Retention Schedule and procedures as provided in this policy. All employees should note the following general exception to any stated destruction schedule: If you believe, or are informed that certain records are relevant to current litigation or contemplated litigation (that is, a dispute that could result in litigation), government investigation, audit, or other event, you must preserve and not delete, dispose, destroy, or change those records, including emails and other electronic documents, until the Legal department OR any other department determines those records are no longer needed. Preserving documents includes suspending any requirements in the Record Retention Schedule and preserving the integrity of the electronic files or other format in which the records are kept.<br />
                If you believe this exception may apply, or have any questions regarding whether it may apply, please contact the Records Management Officer or Legal department.<br />
                In addition, you may be asked to suspend any routine data disposal procedures in connection with certain other types of events, such as our merger with another organisation or the replacement of our information technology systems.<br />
                WHERE TO GO FOR ADVICE AND QUESTIONS<br />
                Questions about the policy. Any questions about this policy or retention periods relevant to your function or department should be raised with  RMO or DPO . <br />
                <br />  <h3>BREACH REPORTING AND AUDIT</h3> <br />
                Reporting policy breaches. We are committed to enforcing this policy as it applies to all forms of data. The effectiveness of our efforts, however, depend largely on employees. If you feel that you or someone else may have breached this policy, you should report the incident immediately to your supervisor. If you are not comfortable bringing the matter up with your immediate supervisor, or do not believe the supervisor has dealt with the matter properly, you should raise the matter with the Records Management Officer or manager at the next level above your direct supervisor. If employees do not report inappropriate conduct, we may not become aware of a possible breach of this policy and may not be able to take appropriate corrective action.<br />
                No one will be subject to and we do not allow, any form of discipline, reprisal, intimidation, or retaliation for reporting incidents of inappropriate conduct of any kind, pursuing any record destruction claim, or co-operating in related investigations.<br />
                Audits. Our Records Management Officer will periodically review this policy and its procedures to ensure we are in compliance with relevant new or amended laws, regulations or guidance. Additionally, we will regularly monitor compliance with this policy, including by carrying out audits.<br />
                <br /> <h3>OTHER RELEVANT POLICIES</h3><br />
                This policy supplements and should be read in conjunction with our other related policies and procedures in force from time to time.<br />
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
