import { useState } from 'react';
import Container from '../Container';
import Check from '../utils/Check';
import { ButtonLink } from '../Button';
import { motion } from "framer-motion"
import LocationPin from '../utils/LocationPin';
import ListItem from '../utils/ListItem';

const points = [
  'Multiple Channels for SOS Requests.',
  'Data powered Effective and Faster Response.',
  'Leveraging 2-Wheelers for faster response',
  'A network of Industry leading Agencies for all incidents',
]

export default function IncidentReport() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => setImageLoaded(true);
  return (
    <motion.section initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
      <Container className="py-32 lg:pt-60 text-left">
        <div className="flex flex-col lg:flex-row lg:space-x-8 xl:space-x-16">
          <div className="bg-accent w-full lg:w-1/2 hidden lg:flex flex-col justify-center relative">
            <div className="w-72 absolute -left-20 -top-24 items-center flex flex-col">
              <div className="hidden md:block w-full h-520px relative" width="auto" height="520px">
                <img src={require('../../assets/images/location-section.png')} alt="lagos map section"
                  className="hidden md:block w-full h-520px rounded-md drop-shadow-2xl" onLoad={handleImageLoad}
                />
                {imageLoaded
                  ? <>
                    <LocationPin className="absolute top-1/4 left-0 right-0 mx-auto rotate-scale-svg" />

                    <div className="bg-white w-64 text-center p-5 space-y-4 absolute bottom-0 rounded-t-lg drop-shadow-lg left-0 right-0 mx-auto">
                      <div className="mb-8">
                        <p className="text-sm font-semibold mb-2">Help is on the way</p>
                        <p className="text-xs text-grey px-3">We'll text you right away. Respond if you can</p>
                      </div>
                      <span className="block py-2 px-4 bg-red rounded-md text-sm text-white font-semibold hover:cursor-pointer">
                        I'm fine and don't help
                      </span>
                      <p className="text-xs text-grey p-2">More safety tools</p>
                    </div>
                  </>
                  : null}
              </div>
            </div>
            <div className="w-2/4 self-end space-y-10">
              <p className="text-sm font-semibold text-black">Incidents report</p>
              <div>
                <h3 className="text-4xl font-bold mb-2">45%</h3>
                <p className="text-sm text-gray-800">Incidents completed</p>
              </div>
              <div>
                <h3 className="text-4xl font-bold mb-2">$4,500.99</h3>
                <p className="text-sm text-gray-800">Outstanding balance</p>
              </div>
            </div>
          </div>
          <div className="text-left w-full lg:w-1/2">
            <div className="w-full lg:pr-7">
              <h2 className="text-4xl lg:text-6xl font-extrabold mb-4 text-black w-full lg:w-3/4">Technology for Public Safety</h2>
              <p className="text-lg font-normal leading-7 text-gray-600">We’re building a reliable and efficient safety ecosystem by partnering with industry leaders in both private and public security, safety, and medical response agencies.</p>
              <ul className="block my-10 space-y-5">
                {points.map((point, index) => (
                  <ListItem key={index}>
                    <div className="flex items-center" key={index}>
                      <div className="w-6 h-6 mr-3">
                        <Check />
                      </div>
                      <span className="block text-lg text-gray-600">{point}</span>
                    </div>
                  </ListItem>
                ))}
              </ul>
              <div className="space-x-4">
                <ButtonLink href="mailto:developer@sety.io" className="px-4 py-2">Get started</ButtonLink>
                <ButtonLink href="mailto:info@sety.io" className="px-4 py-2" color="secondary">Get in touch</ButtonLink>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </motion.section>
  )
}
