import { useRef } from 'react';
import { motion, useScroll } from "framer-motion"
import { Header, Footer, RightHero, Platform, SoS, Agenda } from '../components/common';
import { Tools, Screenshots } from '../components/setyapp';
import { ButtonLink } from '../components/Button';
import { Appstore, Playstore } from '../components/utils/Icons';
import background from "../assets/images/app-hero-img.jpg";
import { GetHelp, Community, Intelligence } from '../components/utils/Icons'

const data = [
  {
    dt: 'Get help',
    dd: 'Safely summon help to your exact location with just the tap of a button',
    icon: <GetHelp />,
  },
  {
    dt: 'Community',
    dd: 'Create a safety circle of friends and family so you can all look out for each other',
    icon: <Community />,
  },
  {
    dt: 'intelligence',
    dd: 'Receive incidents and security alert in real-time, avoid hotspots and danger zones',
    icon: <Intelligence />,
  },
];


export default function SetyApp() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div ref={scrollRef} style={{ backgroundColor: '#F6F5FA', overflow: "scroll" }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-yello h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main>
        <RightHero
          banner={background}
          title="The perfect Safety App"
          subtitle="Wherever you and your loved ones are, safety is always a tap away."
        >
          <ButtonLink className="flex items-center bg-alt px-10" href="https://apps.apple.com/us/app/sety/id1532745340">
            <Appstore />
            <div className="text-black ml-4">
              <span className="block text-xs mb-0 font-light">Download</span>
              <span className="block text-sm font-semibold">App Store</span>
            </div>
          </ButtonLink>
          <ButtonLink color="white" className="flex items-center px-10" href="https://play.google.com/store/apps/details?id=com.sety&hl=en&pli=1">
            <Playstore />
            <div className="text-black ml-4">
              <span className="block text-xs mb-0 font-light">Download</span>
              <span className="block text-sm font-semibold">Google Play</span>
            </div>
          </ButtonLink>
        </RightHero>
        <Tools />
        <Screenshots />
        <Platform />
        <SoS />
        <Agenda
          title="Your perfect safety companion"
          agendas={data}
        />
      </main>
      <Footer />
    </div>
  )
}
