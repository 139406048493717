import Container from "../Container"
import { ButtonLink } from '../Button';
import { Appstore, Playstore } from '../utils/Icons';

export default function Screenshots() {
    return (
        <section className="mb-24">
            <Container className="text-center">
                <h2 className="text-5xl xl:text-6xl font-black text-lightout mb-2">Your #1 Go to Safety App</h2>
                <p className="text-xl lg:text-2xl text-lightout w-full lg:w-1/2 mx-auto">
                    Where ever you and your loved ones are, safety is always a tap away.
                </p>
                <div className="flex space-x-4 justify-center my-8">
                    <ButtonLink className="flex items-center bg-alt px-10" href="https://apps.apple.com/us/app/sety/id1532745340">
                        <Appstore />
                        <div className="text-black ml-4">
                            <span className="block text-xs mb-0 font-light">Download</span>
                            <span className="block text-sm font-semibold">App Store</span>
                        </div>
                    </ButtonLink>
                    <ButtonLink color="white" className="flex items-center px-10" href="https://play.google.com/store/apps/details?id=com.sety&hl=en&pli=1">
                        <Playstore />
                        <div className="text-black ml-4">
                            <span className="block text-xs mb-0 font-light">Download</span>
                            <span className="block text-sm font-semibold">Google Play</span>
                        </div>
                    </ButtonLink>
                </div>
                <div className="flex flex-col lg:flex-row flex-wrap">
                    <div className="w-full lg:w-1/3 flex lg:flex-col justify-center flex-wrap">
                        <img src={require('../../assets/images/sos.svg').default} alt="SOS service" />
                    </div>
                    <div className="w-full lg:w-1/3 flex lg:flex-col justify-center flex-wrap">
                        <img src={require('../../assets/images/help-way-2.svg').default} alt="Help page" className="drop-shadow" />
                        <img src={require('../../assets/images/help-way.png')} alt="Help page 2" className="drop-shadow-md" />
                    </div>
                    <div className="w-full lg:w-1/3 flex lg:flex-col justify-center">
                        <img src={require('../../assets/images/chat-expanded.png')} alt="Help page 2" />
                    </div>
                </div>
            </Container>
        </section>
    )
}
