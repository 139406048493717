import { Button } from '../Button';

export default function EasyIntegration() {
  return (
    <section className="mb-32 py-32 text-center bg-bgray max-w-screen-2xl mx-auto">
      <h2 className="w-full xl:max-w-screen-md text-center mx-auto text-4xl lg:text-6xl font-black text-primary mb-5">Easy setup and integration</h2>
      <p className="w-full xl:max-w-screen-md text-center mx-auto text-xl lg:text-2xl text-gray-600 mb-8">Our estate management software is simple to instal and we'll your estate up and running on our secure solutions in less than 24hrs.</p>
      <Button href="mailto:info@sety.io?subject=Enquiry on enrolling my estate" className="mt-4" >Enroll your estate</Button>
    </section>
  )
}
