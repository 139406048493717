import clsx from 'clsx'

const baseStyles = {
    solid:
        'group inline-flex items-center justify-center py-3 lg:py-4 px-6 md:px-8 rounded text-base xl:text-md font-normal lg:font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 hover:cursor-pointer focus:ring transition-all	duration-300 ease-in-out',
}

const variantStyles = {
    solid: {
        primary: 'bg-primary text-white hover:bg-primaryLight',
        secondary: 'bg-gray-500 text-black hover:bg-gray-300',
        white: 'bg-white text-black hover:bg-white',
        accent: 'bg-accent text-black'
    },
}

export function Button({
    variant = 'solid',
    color = 'primary',
    className,
    ...props
}) {
    return (
        <button
            className={clsx(
                baseStyles[variant],
                variantStyles[variant][color],
                className
            )}
            {...props}
        />
    )
}

export function ButtonLink({
    variant = 'solid',
    color = 'primary',
    href,
    className,
    label,
    ...props
}) {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
            href={href}
            targer="_blank" rel="noreferrer"
            className={clsx(
                baseStyles[variant],
                variantStyles[variant][color],
                className
            )}
            {...props}
        />
    )
}
