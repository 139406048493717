import React from 'react'

export default function LocationPin(props) {
    return (
        <svg
            width={101}
            height={101}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                opacity={0.16}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68.873 97.208a50.22 50.22 0 0 1-18.519 3.515C22.547 100.723.003 78.179.003 50.37S22.546.018 50.354.018c5.16 0 10.137.775 14.823 2.217l-.013.004.16.041c.502.156 1 .32 1.495.491a4.4 4.4 0 0 1-3.056 8.188l-3.782-.945.036.021a41.58 41.58 0 0 0-9.663-1.132c-22.9 0-41.466 18.566-41.466 41.467 0 22.902 18.565 41.467 41.467 41.467 3.852 0 7.58-.525 11.12-1.508l-.013.027.584-.19c.764-.224 1.518-.47 2.263-.736l1.043-.339a4.437 4.437 0 0 1 5.273 2.109l.128.235a4.183 4.183 0 0 1-1.886 5.77l.005.003Zm19.814-14.187c-.233.273-.47.545-.708.813a4.306 4.306 0 0 1-6.5-5.555l1.823-2.726a41.286 41.286 0 0 0 8.52-25.183c0-10.718-4.067-20.486-10.741-27.846l-.355-.426a4.413 4.413 0 0 1-.349-5.169c1.514-2.415 4.886-2.783 6.885-.751l.718.729c7.917 8.895 12.727 20.618 12.727 33.463a50.126 50.126 0 0 1-9.656 29.657l-2.364 2.994Z"
                fill="#EB5757"
            />
            <path
                opacity={0.34}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.28 50.37c0 20.393-16.532 36.925-36.925 36.925-20.393 0-36.925-16.532-36.925-36.926 0-3.68.538-7.235 1.54-10.59l.755-2.2a3.88 3.88 0 0 1 7.352 2.486l-.086.26a29.293 29.293 0 0 0-1.825 10.21c0 16.213 13.143 29.355 29.354 29.355 16.212 0 29.355-13.142 29.355-29.354S66.733 21.18 50.52 21.18a29.22 29.22 0 0 0-14.59 3.877l.006-.008-4.363 2.369a3.65 3.65 0 0 1-3.558-.043c-2.137-1.226-2.47-4.173-.661-5.846l.36-.332a36.765 36.765 0 0 1 22.64-7.754c20.394 0 36.926 16.532 36.926 36.925Z"
                fill="#EB5757"
            />
            <path
                d="M50.532 59.002a8.455 8.455 0 1 0 0-16.91 8.455 8.455 0 0 0 0 16.91Z"
                fill="#EB5757"
                stroke="#fff"
                strokeWidth={1.918}
            />
        </svg>
    )
}
