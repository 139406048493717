import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './pages';
import Loading from './components/Loading';
import ScrollToTop from './components/ScrollToTop';

const Business = lazy(() => import('./pages/business'));
const Application = lazy(() => import('./pages/app'));
const Developers = lazy(() => import('./pages/developers'));
const Partners = lazy(() => import('./pages/partners'));
const Estate = lazy(() => import('./pages/estate'));
const Privacy = lazy(() => import('./pages/privacy'));
const Terms = lazy(() => import('./pages/terms'));

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/app" element={<Application />} />
          <Route path="/business" element={<Business />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/estate" element={<Estate />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
