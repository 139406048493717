import { useState } from 'react';
import { motion } from "framer-motion"
import Container from '../Container'
import { ButtonLink } from '../Button';
import { Playstore, Appstore } from '../utils/Icons';

export default function SoS() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => setImageLoaded(true);
  return (
    <motion.section className="py-32 lg:py-52 px-2 bg-white">
      <Container className={`flex flex-col-reverse lg:flex-row justify-between items-center bg-primary rounded-2xl px-8 lg:px-20 py-20 
        ${imageLoaded && "lg:py-0"}`}>
        <div className="w-full xl:w-3/4">
          <h2 className="text-4xl xl:text-7xl text-white font-black">Sety Safety app</h2>
          <p className="text-base lg:text-xl xl:text-2xl text-[#E3E0F6] w-full xl:w-9/12 mt-4">
            Feeling unsafe, or witnessing any danger, Sety Safety app give you access to all our response network at the touch of a button.
          </p>
          <div className="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-6 mt-9">
            <ButtonLink className="flex items-center bg-alt px-10" href="https://apps.apple.com/us/app/sety/id1532745340">
              <Appstore />
              <div className="text-black ml-4">
                <span className="block text-xs mb-0 font-light">Download</span>
                <span className="block text-sm font-semibold">App Store</span>
              </div>
            </ButtonLink>
            <ButtonLink color="white" className="flex items-center px-10" href="https://play.google.com/store/apps/details?id=com.sety&hl=en&pli=1">
              <Playstore />
              <div className="text-black ml-4">
                <span className="block text-xs mb-0 font-light">Download</span>
                <span className="block text-sm font-semibold">Google Play</span>
              </div>
            </ButtonLink>
          </div>
        </div>
        <div className="hidden lg:block h-auto min-h-[330px] xl:min-h-[440px] lg:-mt-4 lg:-mb-10">
          <img className="relative w-[300px] bottom-28"
            src={require('../../assets/images/soss.png')} alt="screenshot from the sety mobile app"
            onLoad={handleImageLoad}
          />
        </div>
      </Container>
    </motion.section>
  )
}
