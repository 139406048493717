import Container from '../Container';
import ListItem from '../utils/ListItem';

const points = [
  'Effective, centralized, data driven emergency response platform, leveraging AI and Machine Learning  to deliver public safety',
  'An integrated platform, providing connectivity, response, surveillance, and dispatch.',
  'Enabling proactive threat detection and incidence reporting with minimal human intervention.',
]


export default function Mission() {
  return (
    <section className="py-4">
      <Container className="flex flex-col xl:flex-row justify-between items-center">
        <div className="w-full lg:w-3/5 lg:pr-10 lg:mx-auto xl:mx-0">
          <h2 className="text-4xl xl:text-7xl font-bold mb-8">The future of public safety and security</h2>
          <ul className="w-full xl:w-3/4 space-y-8">
            {points.map((point, index) => (
              <ListItem key={index}>
                <div key={index} className="border border-solid border-gray-300 rounded p-6 lg:p-8 text-[#4F4F4F] text-base lg:text-xl">
                  {point}
                </div>
              </ListItem>
            ))}
          </ul>
        </div>
        <div className="hidden xl:block w-full lg:w-2/5">
          <img src={require('../../assets/images/mission.svg').default} alt="" />
        </div>
      </Container>
    </section>
  )
}
