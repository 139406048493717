import Container from '../Container';
import { ButtonLink } from '../Button';

export default function Report() {
  return (
    <section className="max-w-screen-2xl mx-auto relative py-8">
      <Container className="flex flex-col lg:flex-row items-start justify-between">
        <div className="hidden md:block mb-8 lg:mb-0 lg:w-6/12 max-w-2xl">
          <img src={require('../../assets/images/incident-report.png')} className='drop-shadow-sm' alt="dashboard overview" />
        </div>
        <div className="md:hidden mb-8 lg:mb-0 lg:w-6/12 max-w-2xl">
          <img src={require('../../assets/images/incident-report-mobile.png')} alt="dashboard overview" className="rounded-lg" />
        </div>
        <div className="pl-4 lg:pl-4/100 w-full lg:w-6/12 lg:max-w-screen-sm">
          <h2 className="text-4xl lg:text-6xl font-black mb-8">Partnership for public safety</h2>
          <p className="text-sm lg:text-xl text-grey2 mb-8">We contantly grow our safety network with reliable agencies. Sety eases integration to connect into our safet network and unifies safety tools and resources into one platform.</p>
          <div className="space-x-4">
            <ButtonLink href="mailto:info@sety.io?subject=I want to partner with Sety">Become a partner</ButtonLink>
            <ButtonLink href="mailto:info@sety.io?subject=I want to partner with Sety" color="secondary">Learn more</ButtonLink>
          </div>
        </div>
      </Container>
    </section>
  )
}
