import React from 'react'
import Container from '../Container';

export default function RightHero({ banner, title, subtitle, children }) {
    return (
        <div style={{
            backgroundImage: `linear-gradient(rgba(5, 0, 35, 0.49), rgba(5, 0, 35, 0.49)),url(${banner})`
        }} className="h-mhero lg:h-hero bg-no-repeat bg-center bg-cover relative items-start flex flex-col justify-center overflow-hidden">
            <Container className="text-left flex flex-col items-end">
                <div className="hidden lg:block w-1/2"></div>
                <div className="w-full lg:3/5 xl:w-3/5 text-white space-y-7">
                    <h1 className="text-5xl lg:text-7xl xl:text-8xl font-black">{title}</h1>
                    <p className="text-lg md:text-xl lg:text-2xl w-full">{subtitle}</p>
                    <div className="flex space-x-4">
                        {children}
                    </div>
                </div>
            </Container>
        </div>
    )
}
