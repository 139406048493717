import React, { useRef } from 'react'
import { motion, useScroll } from "framer-motion"
import { Header, Footer } from '../components/common';

export default function Privacy() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div className="" ref={scrollRef} style={{ overflow: "scroll" }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-emphatic h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main className="" data-spy="scroll" data-target="#navbar-example2" data-offset="0">
        <section className="mx-auto max-w-7xl px-4 md:px-8 lg:px-10 flex flex-col lg:flex-row items-center mt-24" id="Services">
          <div className="container">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold"><span className="text-primary">SETY'S</span> PRIVACY POLICY</h2>
            </div>

            <div className="pb-12 page-content">
              <p>
                Sety (“we”, “our”, or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personal information is collected, used, and disclosed by Sety.
              </p>
              <br />
              <p>
                This Privacy Policy applies to our website, and its associated subdomains, including our Sety application (collectively, our “Service”).
              </p>
              <br />
              <h3>CONSENT</h3>
              <p>
                By accessing or using our Service, you signify that you have read, understood, and consent to our collection, storage, use, disclosure and otherwise processing of your personal information as described in this Privacy Policy and our Terms of Service.
              </p>
              <br />
              <p>
                Where you are below 13 or your country's legal age to give consent, please call the attention of your parent or guardian to review and consent to this privacy policy before using our Service. By this, we assume that your parent or guardian has read this Privacy Policy and consents to its terms.
              </p>
              <br />
              <h3>
                DEFINITION
              </h3>
              <br />
              <p>
                In this Privacy Policy, the following words and expression shall have the meanings as set out hereunder:  <br />

                “Device” could be any gadget with internet who can access the service such as a mobile phone, a computer, or a digital tablet.  <br />

                “Personal Data/Information” is any information that relates to a natural person who can be identified, directly or indirectly, whether by name, address, a photo, an email address, or other unique identifier etc.  <br />


                “Service Provider” means any natural or legal person who processes data on behalf of Sety. It refers to third-party companies or individuals engaged by Sety to facilitate the Service, to provide the Service on behalf of Sety, to perform services related to the Service or to assist Sety in analysing how the Service is used.   <br />


                “Usage Data” refers to the data that’s been collected automatically, either generated using the Service or from the Service infrastructure itself. An example of this is how many times the user accessed the website/app.  <br />


                “User” (“you” in this Agreement) refers to the person who accessed the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.  <br />

              </p>
              <br />
              <h3>PRIVACY OF CHILDREN </h3>
              <br />
              <p>
                We respect the privacy of children and do not generally offer our services to them or collect their personal information. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we realize that we have collected the personal information of a person under legal age without parental/guardian consent, we will take steps to remove/delete such information from our servers.
              </p>
              <br />
              <h3>INFORMATION WE MAY COLLECT</h3>
              <br />
              <p>
                We collect information from you when you visit our website/app, register on our site, subscribe to our newsletter, respond to a survey, fill out a form or in any other way interact with us. Such information includes your:
              </p>
              <ul>
                <li>Email address</li>
                <li>Name / username</li>
                <li>Physical address</li>
                <li>Age</li>
                <li>Social media profile information</li>
              </ul>
              <br />
              <p>
                We also collect the following information from mobile devices for a better user experience, although our access to the information may be restricted by turning off the request buttons. The information includes: </p>
              <ul>
                <li>Location (GPS): Location data helps to create an accurate representation of your interests, and this can be used to bring more targeted and relevant ads to potential customers.</li>
                <li>Camera (Pictures): Granting camera permission allows you to upload any pictures straight from the website/app. You can safely deny camera permissions for our website/app.</li>
              </ul>
              <br />

              <p>
                We only collect personal information to the extent that such information is reasonably necessary for one or more of our functions or activities or is otherwise required or authorised by law. Unless unreasonable or impracticable, we endeavour to collect personal information directly from the data subject to whom it relates. <br />
                We also collect personal data of your emergency contacts(“Circle”) such as contact information. Where you provide the personal information of another data subject (e.g., an emergency contact), you warrant that consent has been given by the data subject to the processing of their personal information by us. <br />
                We may also collect, use and share aggregated data such as statistical or demographic data for any purpose. Aggregated data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific app feature. Where we combine or connect aggregated data with your Personal Data, we shall use same in accordance with this Privacy Policy.<br />
              </p>
              <br />
              <h3>HOW DO WE USE THE INFORMATION WE COLLECT?</h3>
              <br />
              <p>Any of the information we collect from you may be used in one of the following ways:</p>
              <ul>
                <li>To perform and provide our emergency services, including processing transactions. </li>
                <li>To personalize your experience (your information helps us to better respond to your individual needs. </li>
                <li>To improve our website/app (we continually strive to improve our website/app. offerings based on the information and feedback we receive from you).</li>
                <li>To administer a contest, promotion, survey or other site feature. </li>
                <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs).</li>
                <li>To send periodic emails including marketing email about our services and features. </li>
              </ul>

              <br />
              <p>We collect Circle personal data to communicate to them the need to download the Sety app, to notify Circle of an incident involving a user and to track members of their Circle. </p>

              <br />
              <p>We do not sell the personal information of our users.</p>
              <br />
              <h3>HOW WE USE YOUR EMAIL ADDRESS</h3>
              <br />
              <p>By submitting your email address on our website/app, you agree to receive emails from us. You can cancel your participation in any of these email lists at any time by clicking on the opt-out link or other unsubscribe option that is included in the respective email. We only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send unsolicited commercial emails, because we hate spam as much as you do. </p>
              <br />
              <p>By submitting your email address, you also agree to allow us to use your email address for customer audience targeting on other sites, where we display custom advertising to specific people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the same email to us through another method, we may use it for any of the purposes stated in this policy. </p>
              <br />
              <p>If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email. </p>
              <br />
              <h3>OUR LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA</h3>
              <br />
              <p>Beyond consent, other legal basis for our processing of your information include:</p>
              <ul>
                <li>Where we need information to perform our contract with you </li>
                <li>Where we need to comply with a legal obligation</li>
                <li>Where we need to protect you or another person’s vital interests </li>
                <li>Where it is needed in public interest or for official purposes</li>

              </ul>
              <br />
              <p>We do not use your information for any other purpose than for the purposes listed out in this Privacy Policy. </p>
              <br />
              <h3>HOW DO WE SHARE YOUR PERSONAL DATA?</h3>
              <br />
              <p>
                It may be necessary for us to share your personal data with third parties such as: governmental bodies, regulatory authorities, affiliates, authorized agents, law enforcement agencies, courts/tribunals and service providers.

                We could disclose any information about you as required by law and we may make such disclosures to law enforcement agencies and government officials, as necessary or appropriate under the relevant circumstance. Other than these instances, we do not disclose or share your personal data provided to us without your authorisation.<br />
                <br />
                Where we share your information, we will ensure to communicate our privacy policy to the party receiving your information and endeavour to inform them of their obligations with respect to your information. We cannot, however, be responsible for how they use your information and we disclaim any liabilities for their use of your information.<br />
              </p>
              <br />
              <h3>HOW LONG DO WE KEEP YOUR INFORMATION?</h3><br />
              <p>We keep your information only for as long as we need it to provide our services to you and fulfil the purposes described in this policy. This is also the case for anyone that we share your information with, and who carries out services on our behalf. When we no longer need to use your information and there is no need for us to keep it to comply with our legal or regulatory obligations, we’ll either remove it from our systems or depersonalize it so that you can't be identified</p><br />
              <h3>HOW DO WE PROTECT YOUR INFORMATION?</h3>
              <br />
              <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our payment gateway providers database only to be accessible by those authorized with special access rights to such systems and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute security of any information you transmit to Sety or guarantee that your information on the Service may not be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or managerial safeguards.</p>
              <br />
              <h3>COOKIES</h3>
              <br />
              <p>Sety uses "Cookies" to identify the areas of our website/app that you have visited. A cookie is a small piece of data stored on your computer or mobile device by your web browser. We use cookies to personalize the content that you see on our website/app. Most web browsers can be set to disable the use of cookies. However, if you disable cookies, you may not be able to access functionality on our website/app correctly or at all. We never place personally identifiable information in cookies. </p>
              <br />
              <h3>LINKS TO OTHER WEBSITES</h3>
              <br />
              <p>Our website, and services may contain links to third-party websites, products, and services that are not operated by us. Our products and services may also use or offer products or services from third parties. Information collected by third parties, which may include such things as location data or contact details are governed by their privacy practices and we will not be liable for any breach of confidentiality or privacy of your information on such third-party websites. We encourage you to learn about the privacy practices of those third parties as your consent will allow third parties have access to your data.</p>
              <br />
              <p>You acknowledge and agree that Sety shall not be responsible for any third-party services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Sety does not assume and shall not have any liability or responsibility to you or any other person or entity for any third-party services. Third-party services and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties' terms and conditions. </p>
              <br />
              <h3>PAYMENT DETAILS</h3>
              <br />
              <p>Card and other payment processing details inputted on our app/website are not stored by us but by our payment partner who has access to necessary data as a part of processing your payment for the services requested on the website. By submitting your personal data you agree to this transfer, storing or processing by our payment service provider. We will take all steps reasonably necessary to ensure that your personal data is treated securely and in accordance with this privacy policy. </p>
              <br />
              <h3>GOOGLE PLACE</h3>
              <br />
              <p>Google Places is a service that returns information about places using HTTP requests. It is operated by Google. Google Places service may collect information from you and your device for security purposes. The information gathered by Google Places is held in accordance with the privacy policy of Google and you should ensure that you read the policy </p>
              <br />
              <h3>GOVERNING LAW</h3>
              <br />
              <p>The laws of the Federal Republic of Nigeria shall govern this privacy policy and your use of -our website/app. </p>
              <br />
              <h3>YOUR RIGHTS IN RELATION TO YOUR PERSONAL INFORMATION</h3>
              <br />
              <p>Under the Nigeria Data Protection Regulation, You have a number of rights in relation to your personal information, these include the right to:</p>
              <ul>
                <li>Request information about any of your personal data which we are processing, and request access to your personal information which we possess.  </li>
                <li>Request correction of personal information that we hold about you to make them more accurate or to reflect change in circumstances which the personal information we possess does not reflect. As it is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes by contacting <a href="mailto:help@sety.io">help@sety.io</a></li>
                <li>Request us to refrain from doing certain things with your data or restrict the extent of our collection or processing of your data.</li>
                <li>Request partial or complete erasure of your personal information.</li>
                <li>Object to our processing of your personal information where we are processing your personal information for direct marketing purposes.</li>
                <li>Object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you.</li>
                <li>Request the transfer of your personal information to another party.</li>

              </ul>
              <br />
              <p>These rights are subject to any permissions under applicable laws to refuse your requests as we might continue to process your data or retain such data if there are valid legal, regulatory or operational reasons.</p>
              <br />
              <h3>INFORMATION REGARDING YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION REGULATION (GDPR)</h3>
              <br />
              <p>For the purpose of this privacy policy, we are a data controller of your personal information.</p>
              <br />
              <p>If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information, as described in this privacy policy, depends on the information we collect and the specific context in which we collect it. </p>
              <br />
              <p>We may process your personal information because:</p>
              <ol>
                <li>We need to perform a contract with you, such as when you use our services  </li>
                <li>You have given us permission to do so</li>
                <li>The processing is in our legitimate interests, and it's not overridden by your rights</li>
                <li>For payment processing purposes</li>
                <li>To comply with the law</li>
              </ol>
              <br />
              <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In certain circumstances, you have the following data protection rights:</p>
              <ol>
                <li>The right to access, update or to delete the personal information we have on you </li>
                <li>The right of rectification</li>
                <li>The right of restriction</li>
                <li>The right to data portability</li>
                <li>The right to withdraw consent</li>
              </ol>
              <br />
              <p>Please note that we may ask you to verify your identity before responding to such requests.<br />You have the right to complain to a Data Protection Authority about our collection and use of your personal information.</p>
              <br />
              <h3>CALIFORNIA RESIDENTS</h3>
              <br />
              <p>The California Consumer Privacy Act (CCPA) requires us to disclose categories of personal information we collect and how we use it, the categories of sources from whom we collect personal information, and the third parties with whom we share it, which we have explained above.</p>
              <br />
              <p>We are also required to communicate information about rights California residents have under California law. You may exercise the following rights:</p>
              <ul>
                <li>Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of personal information we collect, use, or share; (2) purposes for which categories of personal information are collected or used by us; (3) categories of sources from which we collect personal information; and (4) specific pieces of personal information we have collected about you. </li>
                <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</li>
                <li>Right to Delete. You may submit a verifiable request to close your account and we will delete personal information about you that we have collected</li>
                <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
              </ul>
              <br />
              <p>Your continued use of our website will be regarded as an acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.<br />If you make a request, we have one month to respond to you. </p>
              <br />
              <h3>CALIFORNIA ONLINE PRIVACY PROTECTION ACT (CALOPPA)</h3>
              <br />
              <p>According to CALOPPA, personal information is defined as any data that may be used to identify a person<br />Under CALOPPA , California consumers have the right to:</p>
              <ul>
                <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
                <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data</li>
              </ul>
              <br />
              <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
              <br />
              <h3>CHANGES TO OUR PRIVACY POLICY</h3>
              <br />
              <p>We may modify this privacy policy from time to time without prior notice by publishing a new version. Any changes to the privacy policy will be reflected on our app and website and will become effective immediately upon publication. We encourage you to periodically review this privacy policy to stay informed about how we are protecting the personally identifiable information we collect. If changes to the privacy policy are material, we will do our best to notify you via email or through a notification on our Services and update the privacy policy modification date below.</p>
              <br />
              <h3>CONTACT</h3>
              <br />
              <p>Questions, comments and requests regarding this privacy policy and your rights are welcomed and should be addressed to help@sety.io. If you are aware of any infringement of this privacy policy, do contact us and we shall work to remedy the situation within a reasonable time.</p>

            </div>


          </div>
        </section>

        {/* <Help /> */}

      </main>
      <Footer />
    </div>
  )
}
