import React from 'react'
import { motion } from 'framer-motion';
import { ListItem } from '../utils';
import { IncidentRequest, IncidentVerification, ResponseDispatch, RealtimeSharing } from '../utils/Icons'

const values = [
  {
    dt: 'Incident request',
    dd: 'SOS request can be intiated via phone call to our dedicated emergency line, via our panic app, or through integrations to existing mobile aoos or connected devices.',
    icon: <IncidentRequest />,
  },
  {
    dt: 'Incident verification',
    dd: 'All incident / emergency requests are verified by our 24/7 control room, to access severity, and verify critical lifesaving data. where there is no response for verification, we will trigger dispatch with high priority.',
    icon: <IncidentVerification />,
  },
  {
    dt: 'Real-time data sharing',
    dd: 'Intelligent emergency data is sent to Safety network for faster and smarter response. Data shared include incident location, victims\' data, vehicle information, medical records, telematics, etc.',
    icon: <RealtimeSharing />,
  },
  {
    dt: 'Response is dispatched',
    dd: 'Emergency response is immediately dispatched, equiped with real-time information of emergency and victim\'s information for fast response.',
    icon: <ResponseDispatch />,
  },
]

export default function Overview() {
  return (
    <motion.section className="py-8 px-4 md:px-6 lg:px-8 flex justify-between">
      <div className="w-1/3 hidden lg:block lg:pr-2 xl:pr-24">
        <img src={require('../../assets/images/dashboard-stats.svg').default} alt="" />
      </div>
      <div className="w-full lg:w-3/5">
        <h1 className="text-4xl lg:text-6xl font-bold w-full lg:w-2/3 mb-8">Faster, smarter, emergency response</h1>
        <div className="flex flex-col md:flex-row md:flex-wrap">
          {values.map((value, index) => (
            <ListItem key={index} classNames="w-full md:w-1/2 md:pr-12 lg:pr-8 xl:pr-14 lg:max-w-sm mb-12 lg:mb-8">
              <div className="max-w-[300px]">
                <div className="w-10 flex items-center justify-center mb-2">
                  {value.icon}
                </div>
                <h5 className="font-bold mb-3 text-lg text-[#333333]">{value.dt}</h5>
                <p className="text-[#4F4F4F] text-base md:text-lg xl:text-xl">{value.dd}</p>
              </div>
            </ListItem>
          ))}
        </div>
      </div>
    </motion.section>
  )
}
