import React, { useState } from 'react'
import Container from '../Container';

const tools = [
  {
    title: 'SOS',
    ddata: 'Feeling unsafe or need help? Open the Sety app and hold down the SOS button.Help will be on the way within minutes.'
  },
  {
    title: 'Danger Zone',
    ddata: 'Keep track of and get notified of danger zones around you, ensuring that you stay informed and can take necessary precautions to prioritize your safety and well-being.'
  },
  {
    title: 'Circle',
    ddata: 'Create a circle of friends and family on Sety to look out for each other and keep track of incidents.'
  },
]

export default function Tools() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => setImageLoaded(true);
  return (
    <section className="py-24 lg:py-40 px-2">
      <Container className="flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-1/2 relative flex justify-center">
          <div className="hidden lg:block z-[+1] relative">
            <img src={require('../../assets/images/phone-frame.webp')} alt="" onLoad={handleImageLoad} className='w-80' />
          </div>
          {imageLoaded && <div className="hidden lg:flex items-center justify-center rounded-full w-[420px] h-[420px] bg-bgl absolute top-16 z-0">
            <div className="rounded-full bg-white w-[85%] h-[85%]" />
          </div>}
        </div>
        <div className="w-full lg:w-1/2 space-y-12">
          {tools.map((tool, index) => (
            <div key={index} className="w-full lg:w-2/3 space-y-1">
              <h4 className="text-xl lg:text-2xl font-bold">{tool.title}</h4>
              <p className="text-base lg:text-lg !leading-7 text-[#6D686F]">{tool.ddata}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
