import Container from '../Container';

const partners = [
    { img: require('../../assets/images/partner-1.svg').default },
    { img: require('../../assets/images/partner-2.svg').default },
    { img: require('../../assets/images/partner-3.svg').default },
    { img: require('../../assets/images/partner-4.svg').default },
    { img: require('../../assets/images/partner-5.svg').default },
]
export default function Partners({ patnerText }) {
    return (
        <section className="py-40 lg:py-52 bg-bgray">
            <Container className="flex flex-col items-center">
                <h2 className="text-center text-3xl lg:text-6xl xl:text-7xl w-full lg:w-3/4 font-black text-primary mb-8">
                    Some of the Partners we're working with
                </h2>
                <p className="text-lg lg:text-xl leading-8 text-justify lg:text-center w-full lg:w-3/4 text-gray-600">
                    {patnerText || 'Through our strategic partnership with leading security and response agencies, and our relationships with various other public and private safety agents, we provide coverage principally in Tier1 cities as well as high income Tier2 cities.'}
                </p>
                <div className=" flex flex-wrap mt-12 justify-evenly items-baseline w-full lg:w-3/4 px-3 lg:px-6 space-x-6 space-y-4 lg:space-x-0">
                    {partners.map((partner, index) => (
                        <img key={index} src={partner.img} className="" alt={`Partner ${index}`} />
                    ))}
                </div>
            </Container>
        </section>
    )
}
