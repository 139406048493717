import React from 'react'
import Container from '../Container'
import { IncidentRequest, IncidentVerification, ResponseDispatch, RealtimeSharing } from '../utils/Icons'


const how = [
  {
    dt: 'Incident request',
    dd: 'A user can trigger an alarm on the Sety application when they feel threatned, injured or find themselves in a dangerous situation.',
    icon: <IncidentRequest />
  },
  {
    dt: 'Incident verification',
    dd: 'Once an incident report is received, our call center agents reach out to the user to find out what the situation is. And the nearest responder agent will be notified.',
    icon: <IncidentVerification />
  },
  {
    dt: 'Real-time data sharing',
    dd: 'Having been provided with all the necessary user information like incident report and the user’s location, the responders show up to help with the situation.',
    icon: <RealtimeSharing />
  },
  {
    dt: 'Response is dispatched',
    dd: 'Our call center agents will keep checking in with the user to make sure that they are alright and to get all the information your team needs to assist them.',
    icon: <ResponseDispatch />
  },
]



export default function How() {
  return (
    <section className="max-w-screen-2xl mx-auto relative pt-32">
      <Container className="flex flex-row items-center">
        <div className="pl-4 lg:pl-4/100 w-full lg:w-7/12">
          <h2 className="text-4xl lg:text-6xl font-black mb-8 ">How it all works</h2>
          <div className="">
            {how.map((value, index) => (
              <div key={index} className="w-full lg:max-w-screen-xl mb-8 lg:w-11/12">
                <div className="w-10 flex items-center justify-center mb-2">
                  {value.icon}
                </div>
                <h5 className="font-bold mb-1 text-lg text-[#333]">{value.dt}</h5>
                <p className="text-[#4F4F4F] text-base md:text-lg xl:text-xl font-light">{value.dd}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="hidden lg:block lg:w-5/12 max-w-md absolute right-0">
          <img src={require('../../assets/images/how-stats.svg').default} alt="dashboard overview" />
        </div>
      </Container>
    </section>
  )
}
