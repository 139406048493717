import Container from '../Container';
import { LifesavingData, SimpleAccessible, VettedPartners } from '../utils/Icons'


const values = [
  {
    dt: 'Life-saving data',
    dd: 'Easily create emergency request, with data seurely sent to our response networks.',
    icon: <LifesavingData />
  },
  {
    dt: 'Simple and accessible',
    dd: 'Intuitive and highly robust infrastructure that requires no additional hardware or software.',
    icon: <SimpleAccessible />
  },
  {
    dt: 'Vetted and trusted partners',
    dd: 'Over 25,000 trained responders in our safety network ready to dispatch.',
    icon: <VettedPartners />
  },
];

export default function Communities() {
  return (
    <section className="py-32">
      <Container>
        <h5 className="text-left lg:text-lg text-grey2 lg:text-center font-medium mb-6">Building safer communities with Sety</h5>
        <h2 className="text-left lg:text-center text-4xl lg:text-6xl font-extrabold w-full lg:w-4/5 mx-auto mb-12 xl:mb-20">
          Enhance your business with life-saving emergency response
        </h2>
        <div className="flex flex-col md:flex-row md:flex-wrap justify-center">
          {values.map((value, index) => (
            <div key={index} className="w-full lg:max-w-sm mb-12 lg:mb-0 lg:w-[28%] lg:pl-4 xl:pl-10">
              <div className="w-10 mb-4">
                {value.icon}
              </div>
              <h5 className="font-bold mb-3 text-lg">{value.dt}</h5>
              <p className="text-grey2 text-lg xl:text-xl font-light">{value.dd}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}
