import { useRef } from 'react';
import { motion, useScroll } from "framer-motion"
import { Header, Footer, SoS, RightHero, Partners, Stats, Agenda } from '../components/common';
import { How, Report } from '../components/partners';
import { ButtonLink } from '../components/Button';
import background from "../assets/images/partners-hero-img.jpg";
import { ReliableData, ReducedFalseAlarms, FreeIntegration } from '../components/utils/Icons'

const data = [
  {
    dt: 'Reliable and accurate data',
    dd: 'When your responders receive incident request from Sey, it comes with accurate and verified such as incident type, user details, real-time location, and many more.',
    icon: <ReliableData />,
  },
  {
    dt: 'Reduced false alarms',
    dd: 'Your time and resources are valuable. This is why we combine technology and human intervention to reduce false alarm to as low as 1%.',
    icon: <ReducedFalseAlarms />,
  },
  {
    dt: 'Free integrations cost',
    dd: 'Integrating to our Safety network for emergency response dispatch takes absolutely no effort, and no cost. No investment in hardware or software.',
    icon: <FreeIntegration />,
  },
];

export default function Partner() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div ref={scrollRef} style={{ overflow: "scroll", backgroundColor: '#FFFFFF' }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-yello h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main>
        <RightHero
          banner={background}
          title="Partnership for public safety"
          subtitle="Through strategic partnerships with private and public safety response agencies, we are able to build a reliable safety network for people and communities."
        >
          <ButtonLink href="mailto:info@sety.io?subject=I want to partner with Sety">Become a partner</ButtonLink>
          <ButtonLink href="mailto:info@sety.io?subject=I want to partner with Sety" color="secondary">Learn more</ButtonLink>
        </RightHero>
        <Partners patnerText="Our partnership with leading security and response agencies, and our relationships with various other public and private safety agents, we provide coverage principally in Tier1 cities as well as high income Tier2 cities." />
        <How />
        <Stats />
        <Report />
        <SoS />
        <Agenda
          title="Give people and businesses a lifeline"
          agendas={data}
        />
      </main>
      <Footer />
    </div>
  )
}
