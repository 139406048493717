export default function Check(props) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm3.702-16.298 1.025 1.025-4.61 4.611-1.026-1.025 4.61-4.611Zm-3.586 7.694 7.695-7.694 1.033 1.025-8.728 8.728-4.065-4.066 1.025-1.025 3.04 3.032ZM8 17.455l-4.065-4.066 1.032-1.025 4.058 4.065L8 17.455Z"
                fill="#D6CFFF"
            />
        </svg>
    )
}
