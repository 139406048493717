import React from 'react'
import { motion } from 'framer-motion'
import Container from '../Container'
import { ButtonLink } from '../Button'

export default function Safety() {
  return (
    <motion.section className="mt-0 pt-24 py-12 px-4 md:px-6 lg:px-8 flex justify-between bg-white">
      <Container className="w-full xl:w-6/12">
        <h1 className="text-center text-4xl lg:text-6xl font-bold w-full mb-4">Safe and smooth access to the place you call home</h1>
        <p className='text-center mb-8 text-lg lg:text-2xl text-gray-800'>In today's world, security is paramount. Our estate security solutions are meticulously designed to create a safe living environment, addressing the growing concerns of insecurity. With advanced technology, proactive measures, and tailored approaches, we offer peace of mind, ensuring that your estate remains a fortress of safety for you and your loved ones.</p>
        <div className="flex items-center gap-4 justify-center">
          <ButtonLink href="mailto:info@sety.io">Enroll your estate</ButtonLink>
          <ButtonLink href="mailto:info@sety.io" color="secondary">Get in touch</ButtonLink>
        </div>
      </Container>
    </motion.section>
  )
}
