import React from 'react'
import { motion } from 'framer-motion'
import Container from '../Container'
import { ButtonLink } from '../Button'

export default function Solutions() {
  return (
    <motion.section className="mt-0 pt-32 py-8 px-4 md:px-6 lg:px-8 flex justify-between bg-white">
      <Container className="w-full bg-white">
        <div className="hidden lg:flex justify-end items-end gap-x-8">
          <div className='shadow-xl pb-10 rounded-sm mb-12 relative p-10 lg:max-w-[380px]'>
            <img src={require('../../assets/images/resident-info.svg').default} alt="" />
          </div>
          <div className='shadow-xl p-4 pb-10 rounded-sm relative xl:top-24 max-w-[380px]'>
            <img src={require('../../assets/images/passcode.svg').default} alt="" />
          </div>
        </div>
        <div className='w-full xl:w-4/5 mt-12 mb-8 flex items-center'>
          <div className="pr-2 w-full lg:w-1/2 lg:max-w-[600px]">
            <h5 className="text-3xl lg:text-5xl font-bold mb-4 w-full">Secure & Smart Estate Solutions</h5>
            <p className='mb-8 text-base lg:text-lg text-gray-800 font-light'>Our estate management solution fills the safety gap in your community, ensuring residents and guests feel secure and want to call it home.</p>
            <div className="flex items-center gap-2">
              <ButtonLink href="mailto:info@sety.io?subject=Enquiry on enrolling my estate">Enroll your estate</ButtonLink>
              <ButtonLink href="mailto:info@sety.io?subject=Enquiry on enrolling my estate" color="secondary">Get in touch</ButtonLink>
            </div>
          </div>
          <div className='hidden lg:block shadow-xl p-8 pb-10 rounded-sm w-2/5 max-w-[320px]'>
            <img src={require('../../assets/images/visitors-access.svg').default} alt="" />
          </div>
        </div>
      </Container>
    </motion.section>
  )
}
