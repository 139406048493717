import React from 'react'
import { motion } from 'framer-motion'
import Container from '../Container'
import { EstateAccess, VisitorManagement } from '../utils/Icons'

const values = [
  {
    dt: 'Seamless estate access',
    dd: 'Our estate solution simplifies access management, making it a breeze to welcome guests, receive deliveries, and coordinate with service providers.',
    icon: <EstateAccess />
  },
  {
    dt: 'Streamlined visitor management',
    dd: 'Simplify the process of dealing with guests, set specific time limits for their access, and maintain digital records of their visits, ensuring better organization and security.',
    icon: <VisitorManagement />
  },
]

export default function LivingExperience() {
  return (
    <motion.section className="mt-0 pt-24 py-8 px-4 md:px-6 lg:px-8 flex justify-between bg-white">
      <Container className="flex flex-col xl:flex-row justify-between">
        <div className="w-full xl:w-1/3 hidden xl:block xl:pr-4">
          <img src={require('../../assets/images/gardens.svg').default} alt="" />
          <img src={require('../../assets/images/access-notifications.svg').default} alt="" />
        </div>
        <div className="w-full xl:w-4/5">
          <h1 className="text-4xl lg:text-6xl font-bold w-full mb-12">Elevate Your Estate Living Experience!</h1>
          <div className='flex justify-between align-top'>
            <img src={require('../../assets/images/estate-mgt.svg').default} alt="" className="hidden lg:block" />
            <div className='d-block'>
              {values.map((value, index) => (
                <div key={index} className="w-full lg:w-10/12 space-y-1.5 mb-10">
                  <div className="flex items-center">
                    <div className="w-16 flex items-center justify-center">
                      {value.icon}
                    </div>
                    <h4 className="text-base lg:text-xl font-bold">{value.dt}</h4>
                  </div>
                  <p className="text-base lg:text-xl text-grey2 font-light ml-10">{value.dd}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </motion.section>
  )
}
