import { useRef } from 'react';
import { motion, useScroll } from "framer-motion"
import { Header, Footer, LeftHero, SoS, Agenda } from '../components/common';
import { Request, Access, Integration } from '../components/developers';
import background from "../assets/images/developers-hero-img.png";
import { ReliableData, ReducedFalseAlarms, FreeIntegration } from '../components/utils/Icons'

const data = [
  {
    dt: 'Reliable and accurate data',
    dd: 'When your responders receive incident request from Sey, it comes with accurate and verified such as incident type, user details, real-time location, and many more.',
    icon: <ReliableData />,
  },
  {
    dt: 'Reduced false alarms',
    dd: 'Your time and resources are valuable. This is why we combine technology and human intervention to reduce false alarm to as low as 1%.',
    icon: <ReducedFalseAlarms />,
  },
  {
    dt: 'Free integrations cost',
    dd: 'Integrating to our Safety network for emergency response dispatch takes absolutely no effort, and no cost. No investment in hardware or software.',
    icon: <FreeIntegration />,
  },
];

function App() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div ref={scrollRef} style={{ overflow: "scroll" }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-emphatic h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main>
        <LeftHero
          banner={background}
          title="Connect your Apps and devices to Sety"
          subtitle="Advanced emergency help with a sinple API request. Sety provides an intuitive way to request help without human intervention."
          lb={{ link: 'mailto:developer@sety.io', label: 'Learn more' }}
        />
        <Request />
        <Access />
        <Agenda
          title="Building Safer communities with Sety"
          agendas={data}
          cta={{ label: 'Request for API documentation', action: "/" }}
        />
        <SoS />
        <Integration />
      </main>
      <Footer />
    </div>
  );
}

export default App;
