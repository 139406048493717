import React from 'react'
import Logo from '../components/utils/Logo';

export default function Loading() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-white flex items-center justify-center loading-screen bg-[#ffebfa]">
      <div className="flex flex-col gap-4">
        <Logo className="glow" />
      </div>
    </div>
  )
}
