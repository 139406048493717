import Container from '../Container';
import { Button } from '../Button';

export default function Agenda({ title, agendas, cta }) {
  return (
    <section className="bg-white py-24 pb-32">
      <Container className="text-center">
        <h2 className="text-4xl text-left lg:text-center xl:text-6xl font-black text-lightout mb-12 w-full lg:w-7/12 xl:w-2/3 mx-auto">
          {title}
        </h2>
        <div className="flex flex-col lg:flex-row relative lg:left-4/100">
          {agendas.map((agenda, index) => (
            <div className="mb-8 lg:mb-0" key={index}>
              <div className="flex items-center justify-start space-x-2 mb-1.5 lg:mb-4">
                <div className="w-16 flex items-center justify-center">
                  {agenda.icon}
                </div>
                <h4 className="text-lg text-gray-600 font-bold">{agenda.dt}</h4>
              </div>
              <p className="w-full text-left md:w-10/12 lg:w-2/3 pl-14 lg:pl-0 text-[#5F5F5F] !leading-7 text-[18px] lg:text-lg">{agenda.dd}</p>
            </div>
          ))}
        </div>
        {cta && <Button href="mailto:developer@sety.io" className="mt-16 block mx-auto">Request for API documentation</Button>}
      </Container>
    </section>
  )
}
