import { Button } from '../Button';

export default function Integration() {
  return (
    <section className="py-24 text-center bg-bgray max-w-screen-2xl mx-auto">
      <h2 className="text-4xl lg:text-6xl font-black text-primary mb-5">Seamless integration</h2>
      <p className="text-xl text-gray-600 mb-8">Easy API integration for safety and emergency response</p>
      <Button href="mailto:developer@sety.io" className="mt-4">Request for API documentation</Button>
    </section>
  )
}
