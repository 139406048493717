import ListItem from '../utils/ListItem';
import Check from '../utils/Check';

const points = [
  'Empower your users with access to emergency response when they need help.',
  'Standout from the crowd with advanced safety tool, and access to reliable safety network',
  'Show your users you care about them by partnering with responders and safety agents.',
]

export default function Access() {
  return (
    <section className="bg-black py-16 xl:py-0 px-4 lg:px-0 max-w-screen-2xl mx-auto">
      <div className="flex justify-between items-center">
        <div className="w-full lg:w-7/12 text-white text-left xl:pl-4/100 ml-auto py-15">
          <h2 className="text-4xl lg:text-6xl font-bold lg:font-extrabold mb-6 w-full xl:w-3/4">Give your users access to the best safety service</h2>
          <p className="text-lg lg:text-xl w-full xl:w-3/4 leading-7">We focus on building simple technology for effective response across all emergency types. Most importantly, we build a network of reliable responders to support our technology in creating a protected and safe community.</p>
          <ul className="block mt-14 space-y-10 w-full xl:w-3/4">
            {points.map((point, index) => (
              <ListItem key={index}>
                <div className="flex items-start" key={index}>
                  <div className="p-2 mr-5">
                    <Check className="saturate-50" />
                  </div>
                  <span className="text-base lg:text-xl text-gray-500">{point}</span>
                </div>
              </ListItem>
            ))}
          </ul>
        </div>
        <div className="hidden lg:block lg:w-4/12">
          <img src={require('../../assets/images/platform.webp')} alt="People walking on the street" className="float-right" />
        </div>
      </div>
    </section>
  )
}
