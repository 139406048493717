import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Link, NavLink } from 'react-router-dom';
import Container from '../Container'
import Logo from '../utils/Logo';

const links = [
  {
    title: 'Sety App',
    to: '/app',
  },
  {
    title: 'Estate',
    to: '/estate',
  },
  {
    title: 'Business',
    to: '/business',
  },
  {
    title: 'Partners',
    to: '/partners',
  },
  {
    title: 'Developers',
    to: '/developers',
  },
]

const MobileNavigation = () => {

  return (
    <Popover>
      {({ open, close }) => (
        <>
          <Popover.Button className="relative z-10 flex h-12 w-12 items-center justify-center focus:outline-none">
            <span className="sr-only">Toggle Navigation</span>
            <svg
              aria-hidden="true"
              className="h-4 w-4 overflow-visible stroke-black"
              fill="none"
              strokeWidth={2}
              strokeLinecap="round"
            >
              <path
                d="M0 1H14M0 7H14M0 13H14"
                className={clsx('origin-center transition', {
                  'scale-90 opacity-0': open,
                })}
              />
              <path
                d="M2 2L12 12M12 2L2 12"
                className={clsx('origin-center transition', {
                  'scale-90 opacity-0': !open,
                })}
              />
            </svg>
          </Popover.Button>
          <Transition.Root>
            <Transition.Child
              as={Fragment}
              enter="duration-250 ease-in-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="duration-250 ease-in-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="duration-250 ease-in-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-250 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                as="ul"
                className="absolute -inset-x-5 top-full mt-4 origin-top space-y-4 bg-white p-6 text-lg tracking-tight text-slate-900 shadow-xl"
              >
                <li className="space-y-8">
                  {links.map((link, index) => (
                    <NavLink rel="prefetch" key={`link-${index}`} to={link.to} className="block w-full" onClick={() => close()}>
                      {link.title}
                    </NavLink>
                  ))}
                </li>

              </Popover.Panel>
            </Transition.Child>
          </Transition.Root>
        </>
      )}
    </Popover>
  )
}

export function Header() {
  return (
    <header className="w-screen pb-[5.5rem]">
      <Container>
        <nav className="z-10 text-sm bg-white fixed top-0 left-0 right-0 bg-opacity-[0.75] supports-[backdrop-filter]:bg-opacity-20 backdrop-blur-md supports-[backdrop-filter]:backdrop-blur-md border-b border-white border-opacity-10">
          <ul className="flex items-center justify-between font-[450] py-4 lg:py-6 px-12 xl:px-4 max-w-7xl mx-auto">
            <li className=''>
              <Link to="/" rel="prefetch" href="/">
                <span className="sr-only">Home</span>
                <Logo className="h-10 w-auto" />
              </Link>
            </li>
            {links.map((link, index) => (
              <NavLink
                key={`link-${index}`}
                to={link.to}
                className={(navData) => `
                  rounded-lg py-1 text-[16px] pr-5 text-slate-700 ml-6 hover:bg-slate-100 transition-all duration-300 hover:text-primaryLight 
                  ${index === 0 ? 'ml-auto' : ''}
                  ${navData.isActive ? 'text-primary' : 'font-normal'}
                `}
              >
                {link.title}
              </NavLink>
            ))}
            <li className="ml-5 -mr-1 md:hidden">
              <MobileNavigation />
            </li>
          </ul>
        </nav>
      </Container>
    </header >
  )
}
