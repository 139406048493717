import { useRef } from 'react';
import { motion, useScroll } from "framer-motion"
import { Header, Footer, RightHero, SoS } from '../components/common';
import { ButtonLink } from '../components/Button';
import { LivingExperience, Safety, Solutions, EasyIntegration } from '../components/estate';
import background from "../assets/images/estate-banner.jpg";



export default function Estate() {
  const { scrollYProgress } = useScroll();
  const scrollRef = useRef(null)
  return (
    <div ref={scrollRef} style={{ backgroundColor: '#FFFFFF', overflow: "scroll" }}>
      <motion.div
        className="fixed top-0 left-0 right-0 bg-yello h-0.5 origin-left z-50"
        style={{ scaleX: scrollYProgress }}
      />
      <Header />
      <main>
        <RightHero
          banner={background}
          title="Seamless estate access built on safety"
          subtitle="Streamline access to your estate while prioritizing security and convenience."
        >

          <ButtonLink href="mailto:info@sety.io?subject=I want to enroll my estate">Enroll your estate</ButtonLink>
          <ButtonLink href="mailto:info@sety.io?subject=I want to enroll my estate" color="secondary">Learn more</ButtonLink>
        </RightHero>
        <LivingExperience />
        <Safety />
        <Solutions />
        <SoS />
        <EasyIntegration />
      </main>
      <Footer />
    </div>
  )
}
