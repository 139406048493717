import React from 'react'
import Container from '../Container';
import { ButtonLink } from '../Button';

export default function LeftHero({ title, subtitle, banner, lb }) {
  return (
    <div style={{
      backgroundImage: `linear-gradient(rgba(5, 0, 35, 0.49), rgba(5, 0, 35, 0.49)),url(${banner})`
    }} className="h-mhero lg:h-hero bg-no-repeat bg-center bg-cover relative items-start flex flex-col justify-center">
      <Container className="pl-6 text-left">
        <div className="w-full lg:w-2/3 text-white space-y-6 xl:space-y-10">
          <h1 className="text-4xl lg:text-8xl font-black">{title || ''}</h1>
          <p className="text-[16px] lg:text-2xl w-full xl:w-10/12">{subtitle || ''}</p>
          <div className="flex space-x-4">
            <ButtonLink href="mailto:developer@sety.io">{lb && lb.label ? lb.label : 'Get started'}</ButtonLink>
            <ButtonLink href="mailto:info@sety.io" color="secondary">Get in touch</ButtonLink>
          </div>
        </div>
      </Container>
    </div>
  )
}
