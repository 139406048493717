export function Playstore(props) {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.75 10.875 15.9 7.5 3.675.9C3.6.825 3.45.825 3.225.75l9.525 10.125ZM17.25 15.75l4.425-2.4c.525-.3.825-.75.825-1.35 0-.6-.3-1.125-.825-1.35l-4.425-2.4L13.725 12l3.525 3.75ZM1.8 1.425c-.225.225-.3.525-.3.825v19.5c0 .3.075.6.3.9L11.7 12 1.8 1.425ZM12.75 13.125 3.225 23.25c.15 0 .3-.075.45-.15L15.9 16.5l-3.15-3.375Z"
                fill="#5341C4"
            />
        </svg>
    )
}

export function Appstore(props) {
    return (
        <svg
            width={20}
            height={25}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M19.041 8.234a5.482 5.482 0 0 0-2.608 4.614 5.325 5.325 0 0 0 3.247 4.88 13.221 13.221 0 0 1-1.668 3.425c-1.03 1.49-2.112 2.982-3.762 2.982s-2.059-.959-3.957-.959c-1.9 0-2.503.994-4.011.994S3.727 22.786 2.52 21.1A14.924 14.924 0 0 1 0 13.097c0-4.703 3.052-7.099 6.07-7.099 1.596 0 2.927 1.047 3.92 1.047.995 0 2.45-1.118 4.26-1.118a5.714 5.714 0 0 1 4.791 2.307ZM13.398 3.85A5.412 5.412 0 0 0 14.676.48c.018-.16.018-.32 0-.48a5.43 5.43 0 0 0-3.549 1.775 5.324 5.324 0 0 0-1.331 3.283 1.774 1.774 0 0 0 0 .425h.32a4.668 4.668 0 0 0 3.282-1.632Z"
                fill="#000"
            />
        </svg>
    )
}

export function Quote(props) {
    return (
        <svg
            width={94}
            height={71}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M43.4 52.24C43.4 41.68 36.68 34 24.68 34c-2.4 0-4.8.96-5.28 1.2.72-11.04 12-17.52 20.4-18.48V.4C23.96 1.6.44 13.36.44 46.48c0 12.24 7.92 24.24 22.32 24.24 12 0 20.64-8.4 20.64-18.48Zm50.4 0C93.8 41.68 87.08 34 75.08 34c-2.4 0-4.8.96-5.28 1.2.72-11.04 12-17.52 20.4-18.48V.4C74.36 1.6 51.08 13.36 51.08 46.48c0 12.24 7.68 24.24 22.08 24.24 12 0 20.64-8.4 20.64-18.48Z"
                fill="#5341C5"
            />
        </svg>
    )
}

export function Uber(props) {
    return (
        <svg
            width={93}
            height={27}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M25.216.8v14.4c0 3.696-1.164 6.48-3.492 8.352-2.304 1.872-5.316 2.808-9.036 2.808-3.72 0-6.756-.936-9.108-2.808C1.228 21.68.052 18.896.052 15.2V.8h6.192v14.256c0 1.824.588 3.24 1.764 4.248 1.176.984 2.748 1.476 4.716 1.476s3.504-.492 4.608-1.476c1.128-.984 1.692-2.4 1.692-4.248V.8h6.192Zm15.96 6.84c2.927 0 5.351.828 7.271 2.484 1.92 1.632 2.88 3.912 2.88 6.84 0 2.904-.948 5.196-2.844 6.876-1.872 1.68-4.308 2.52-7.308 2.52-2.688 0-4.776-.696-6.264-2.088h-.324L34.011 26h-4.86V.44h5.904v8.928c1.56-1.152 3.6-1.728 6.12-1.728Zm-.937 13.644c1.632 0 2.904-.384 3.816-1.152.936-.792 1.404-1.848 1.404-3.168 0-1.296-.468-2.328-1.404-3.096-.912-.768-2.184-1.152-3.816-1.152-1.632 0-2.904.384-3.816 1.152-.912.768-1.368 1.8-1.368 3.096 0 1.344.456 2.4 1.368 3.168.936.768 2.208 1.152 3.816 1.152Zm28.93-1.404h5.796c-.36 1.968-1.452 3.54-3.276 4.716-1.824 1.176-4.176 1.764-7.056 1.764-3.384 0-6.108-.84-8.172-2.52-2.064-1.68-3.096-3.912-3.096-6.696 0-2.88 1.032-5.184 3.096-6.912 2.088-1.728 4.704-2.592 7.848-2.592 1.368 0 2.652.18 3.852.54 1.224.336 2.34.864 3.348 1.584 1.008.72 1.8 1.704 2.376 2.952.6 1.224.9 2.652.9 4.284 0 .552-.036 1.116-.108 1.692H59.269c.216.936.78 1.692 1.692 2.268.936.552 2.16.828 3.672.828 2.496 0 4.008-.636 4.536-1.908Zm-4.86-7.668c-2.688 0-4.32.84-4.896 2.52h9.576c-.168-.768-.66-1.38-1.476-1.836-.792-.456-1.86-.684-3.204-.684ZM77.808 26V8h5.363l.54 2.592h.36c.793-1.08 1.633-1.836 2.52-2.268.913-.456 2.172-.684 3.78-.684h1.764v5.868c-.72-.12-1.584-.18-2.592-.18-1.92 0-3.372.516-4.356 1.548-.983 1.032-1.475 2.412-1.475 4.14V26h-5.904Z"
                fill="#C4C3C7"
            />
        </svg>
    )
}

export function Avatar(props) {
    return (
        <svg
            width={16}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.5 9.781c2.179 0 3.944-2.19 3.944-4.89C11.444 2.19 10.864 0 7.501 0 4.136 0 3.557 2.19 3.557 4.89c0 2.702 1.765 4.891 3.944 4.891ZM.074 17.264c-.001-.194-.002-.055 0 0ZM14.97 17.376c.005-.045.002-.313 0 0Z"
                fill="#000"
            />
            <path
                d="M14.94 17.05c-.073-4.704-.675-6.045-5.28-6.894 0 0-.649.844-2.16.844-1.511 0-2.16-.844-2.16-.844-4.555.84-5.194 2.16-5.278 6.742-.007.374-.01.394-.011.35v.495S1.148 20 7.5 20s7.448-2.257 7.448-2.257v-.366c0 .027-.003-.025-.008-.326Z"
                fill="#000"
            />
        </svg>
    )
}

export function Calendar(props) {
    return (
        <svg
            width={19}
            height={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.87 11.015a.447.447 0 0 0-.44-.453H4.893a.447.447 0 0 0-.44.453v1.581c0 .25.197.454.44.454H6.43c.243 0 .44-.203.44-.454v-1.58ZM10.71 11.015a.447.447 0 0 0-.44-.453H8.733a.447.447 0 0 0-.44.453v1.581c0 .25.197.454.44.454h1.537c.243 0 .44-.203.44-.454v-1.58ZM14.552 11.015a.447.447 0 0 0-.44-.453h-1.537a.447.447 0 0 0-.44.453v1.581c0 .25.197.454.44.454h1.537c.243 0 .44-.203.44-.454v-1.58ZM6.87 14.969a.447.447 0 0 0-.44-.453H4.893a.447.447 0 0 0-.44.453v1.58c0 .25.197.453.44.453H6.43c.243 0 .44-.202.44-.453v-1.58ZM10.71 14.969a.447.447 0 0 0-.44-.453H8.733a.447.447 0 0 0-.44.453v1.58c0 .25.197.453.44.453h1.537c.243 0 .44-.202.44-.453v-1.58ZM14.552 14.969a.447.447 0 0 0-.44-.453h-1.537a.447.447 0 0 0-.44.453v1.58c0 .25.197.453.44.453h1.537c.243 0 .44-.202.44-.453v-1.58Z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3 4.639V2.224c.927.028 1.689.818 1.689 1.788v14.154c0 .986-.78 1.8-1.74 1.8H1.756c-.96 0-1.74-.812-1.74-1.8V4.012c0-.97.762-1.76 1.69-1.788v2.415c0 1.091.86 1.97 1.92 1.97h1.213c1.06 0 1.933-.879 1.933-1.97V2.215h5.463v2.424c0 1.091.872 1.97 1.933 1.97h1.212c1.06 0 1.921-.879 1.921-1.97Zm-1.315 13.419a.763.763 0 0 0 .752-.774v-7.31a.763.763 0 0 0-.752-.775H2.986a.763.763 0 0 0-.752.774v7.311c0 .427.337.774.752.774h13Z"
                fill="#000"
            />
            <path
                d="M3.622 5.315H4.82a.668.668 0 0 0 .659-.678V.677A.669.669 0 0 0 4.82 0H3.623a.669.669 0 0 0-.66.678v3.96c0 .374.296.677.66.677ZM14.151 5.315h1.199a.668.668 0 0 0 .659-.678V.677A.668.668 0 0 0 15.35 0h-1.199a.668.668 0 0 0-.659.678v3.96c0 .374.295.677.66.677Z"
                fill="#000"
            />
        </svg>
    )
}

export function Wiper(props) {
    return (
        <svg
            width={25}
            height={27}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m23.28.499-6.225 12.237c.035.01.071.02.106.032.263.086.51.19.74.313.278.149.53.324.756.523l6.238-12.26a.927.927 0 0 0-.39-1.241.903.903 0 0 0-1.224.396Z"
                fill="#000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.323 25.536c-1.407-.65-2.622-1.438-3.612-2.343C1.454 22.043.56 20.704.053 19.212a.632.632 0 0 1 .094-.584.614.614 0 0 1 .532-.244l.064.004h.014c.444.025.89.037 1.336.037 1.124 0 2.251-.074 3.364-.2a22.56 22.56 0 0 0 3.71-.716c.937-.27 1.838-.548 2.66-1.1.617-.414 1.183-.924 1.735-1.42l.349-.313c.641-.57 1.482-1.13 2.358-1.13a1.988 1.988 0 0 1 .615.098c.206.068.409.152.6.255a2.992 2.992 0 0 1 .74.557c.53.56.801 1.346.837 2.116.124 2.69-.514 5.817-2.28 7.898-1.343 1.581-3.291 2.425-5.791 2.508H10.969a.599.599 0 0 1-.083-.005l-.007-.001c-.088-.014-2.18-.338-4.556-1.436Zm-4.476-5.864a.168.168 0 0 0-.148.247c.191.362.412.712.662 1.049a63.036 63.036 0 0 0 1.203.026c.86.01 1.833 0 2.546-.07.085-.008.114.112.034.144-.462.182-1.161.436-1.878.62a9.412 9.412 0 0 1-.544.122 6.439 6.439 0 0 1-.565.086 9.698 9.698 0 0 0 .67.622 61.028 61.028 0 0 0 1.586.045c.9.013 1.954.007 2.714-.067.085-.009.113.111.033.143a17.07 17.07 0 0 1-1.669.564c-.218.06-.44.114-.658.16-.241.05-.479.087-.705.108a14.56 14.56 0 0 0 1.888 1.009 30.84 30.84 0 0 0 1.906-.246c1.08-.176 2.285-.445 3.23-.846.117-.05.21.108.111.189-.432.35-.987.775-1.56 1.15-.185.121-.372.237-.558.343a7.02 7.02 0 0 1-.61.314 15.91 15.91 0 0 0 1.423.333.456.456 0 0 0 .1.007c2.085-.09 3.694-.785 4.783-2.064 1.063-1.25 1.468-2.858 1.61-4.122a.343.343 0 0 0-.221-.362c-1.195-.432-2.87-1.322-3.948-1.923a.335.335 0 0 0-.334.006c-1.855 1.12-4.306 1.862-7.312 2.209a31.786 31.786 0 0 1-3.789.204Z"
                fill="#000"
            />
            <path
                d="M21.775 24.306c.805 0 1.458-.662 1.458-1.478 0-.817-.653-1.478-1.458-1.478-.806 0-1.459.661-1.459 1.478 0 .816.653 1.478 1.459 1.478ZM18.63 26.959a.742.742 0 0 0 .736-.747.742.742 0 0 0-.737-.747.742.742 0 0 0-.736.747c0 .412.33.747.736.747ZM22.625 26.787a.742.742 0 0 0 .737-.747.742.742 0 0 0-.737-.747.742.742 0 0 0-.736.747c0 .412.33.747.736.747Z"
                fill="#000"
            />
        </svg>
    )
}

export function GetHelp(props) {
    return (
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M42.745 37.7584C41.9016 35.8622 40.0202 34.6413 37.9412 34.6413H33.1934V31.1763H36.4313C37.7967 31.1763 38.9025 30.0675 38.9025 28.7022V24.7093H40.5657C41.014 24.7093 41.4062 24.4704 41.592 24.0841C41.763 23.7214 41.7158 23.2997 41.4622 22.9842L38.9025 19.7963V18.9117H38.8907C38.646 13.925 34.5204 9.95276 29.4718 9.95276C24.4232 9.95276 20.0381 14.1757 20.0381 19.3835C20.0381 21.6807 20.8609 23.7892 22.2292 25.4259V34.6413H18.6433C16.5642 34.6413 14.6828 35.8622 13.8394 37.7584L9.41895 47.6816H47.1655L42.745 37.7584ZM29.4718 11.4272C32.0787 11.4272 34.3995 12.6894 35.8504 14.6327H23.0932C24.5441 12.6894 26.8649 11.4272 29.4718 11.4272ZM21.5126 19.3835C21.5126 18.2157 21.7662 17.1069 22.2233 16.1072H36.7203C37.1125 16.9713 37.3543 17.9179 37.4104 18.9117C37.4192 18.9765 37.4222 19.0444 37.4192 19.1092C37.4251 19.2007 37.4281 19.2921 37.4281 19.3835C37.4281 19.6784 37.4133 19.9703 37.3809 20.2564L38.5958 21.7692L38.9025 22.1526L39.7725 23.2348H37.4281V28.7022C37.4281 29.2536 36.9828 29.7019 36.4313 29.7019H31.7189V37.9589H23.7036V24.8567C23.0962 24.2198 22.5948 23.4825 22.2292 22.6745C21.7691 21.6719 21.5126 20.5572 21.5126 19.3835ZM15.1841 38.357C15.7916 36.9946 17.1481 36.1158 18.6433 36.1158H22.2292V39.4334H33.1934V36.1158H37.9412C39.4363 36.1158 40.7928 36.9946 41.4003 38.357L44.8948 46.2071H11.6896L15.1841 38.357Z" fill="#3C329B" />
            <path d="M44.8949 46.207H11.6897L15.1842 38.3569C15.7917 36.9945 17.1482 36.1157 18.6433 36.1157H22.2292V39.4333H33.1934V36.1157H37.9412C39.4363 36.1157 40.7929 36.9945 41.4003 38.3569L44.8949 46.207Z" fill="#BEBBFF" />
            <path d="M36.4844 43.7535C35.5437 43.1696 35.0158 42.2142 34.9185 40.9108L34.8713 40.2767H38.6932L38.6755 40.8842C38.6342 42.2083 38.1004 43.1785 37.0919 43.7624L36.7852 43.9393L36.4844 43.7535Z" fill="white" />
            <path d="M38.0857 40.8665C38.0474 42.0873 37.5549 42.8128 36.797 43.2522C36.0214 42.7715 35.5909 41.9782 35.5083 40.8665H38.0857ZM39.3007 39.6869H34.2344L34.3287 40.9549C34.4703 42.8275 35.411 43.783 36.1748 44.2548L36.7764 44.6264L37.3868 44.2725C38.2096 43.7948 39.2063 42.8246 39.2653 40.9019L39.3036 39.6869H39.3007Z" fill="#3C329B" />
            <path d="M21.1765 15.9597L20.8433 11.3475L38.7316 9.72266L37.6493 15.9597H21.1765Z" fill="#BEBBFF" />
            <path d="M41.3385 16.6969L38.708 14.1992L38.7021 14.2051L39.2919 10.7931L39.6192 8.8999L37.7054 9.07389L21.495 10.5484L20.053 10.6781L20.1562 12.1231L20.3892 15.3286L20.4894 16.6969H38.2716H41.3385ZM21.6307 12.0169L37.841 10.5425L37.0301 15.2224H21.8607L21.6277 12.0169H21.6307Z" fill="#3C329B" />
            <path d="M27.4961 24.2109C25.827 24.2109 24.4675 22.8515 24.4675 21.1824C24.4675 19.5133 25.827 18.1538 27.4961 18.1538C29.1652 18.1538 30.5247 19.5133 30.5247 21.1824C30.5247 22.8515 29.1652 24.2109 27.4961 24.2109ZM27.4961 19.6283C26.638 19.6283 25.942 20.3242 25.942 21.1824C25.942 22.0405 26.638 22.7365 27.4961 22.7365C28.3542 22.7365 29.0502 22.0405 29.0502 21.1824C29.0502 20.3242 28.3542 19.6283 27.4961 19.6283Z" fill="#3C329B" />
            <path d="M27.8028 15.2225H26.3284V18.8881H27.8028V15.2225Z" fill="#3C329B" />
            <path d="M29.2422 22.4477L28.3877 23.6493L35.0781 28.4075L35.9327 27.2059L29.2422 22.4477Z" fill="#3C329B" />
        </svg>

    )
}

export function Community(props) {
    return (
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M25.933 41.0819V43.0783H3.81592V39.8934C3.81592 35.8888 6.6646 32.5329 10.4422 31.7484C10.9199 31.6511 11.4124 31.5921 11.9167 31.5833V34.1961H15.1222C15.4171 33.8333 15.7356 33.4972 16.0806 33.1816V31.5774H16.4286C16.809 31.5774 17.1864 31.601 17.5551 31.6452C17.7762 31.6688 17.9945 31.7042 18.2097 31.7455C18.6137 31.8222 19.0089 31.9254 19.3952 32.0492H19.3982C19.7815 32.176 20.1501 32.3264 20.507 32.4975C23.7125 34.025 25.933 37.2984 25.933 41.0819Z" fill="white" />
            <path d="M24.4467 34.7091C24.1754 34.3641 23.8775 34.0397 23.562 33.733C23.0135 33.1992 22.406 32.7274 21.7484 32.3264C21.3827 32.1022 20.9994 31.9017 20.6042 31.7248C20.212 31.5508 19.805 31.3974 19.3863 31.2736C18.7965 31.0966 18.1861 30.9698 17.555 30.902C17.1864 30.8607 16.8089 30.8401 16.4285 30.8401H16.0805V32.3146H16.4285C16.6733 32.3146 16.918 32.3234 17.1569 32.347C17.2896 32.3558 17.4223 32.3706 17.555 32.3883C17.8145 32.4207 18.0681 32.465 18.3188 32.521C18.6903 32.6006 19.0501 32.7068 19.401 32.8365C22.7776 34.0515 25.1957 37.2894 25.1957 41.0818V42.341H4.55309V39.8934C4.55309 36.2956 7.07444 33.2759 10.4421 32.5062C10.9169 32.3971 11.4094 32.3323 11.9166 32.3205V30.843C11.4123 30.8548 10.9228 30.9079 10.4421 30.9993C6.24873 31.7896 3.07861 35.4699 3.07861 39.8934V43.8155H26.6702V41.0818C26.6702 38.6725 25.8386 36.4578 24.4467 34.7091Z" fill="#3C329B" />
            <path d="M47.3395 31.2559C46.8618 31.1143 46.3693 31.0082 45.865 30.9374V32.4296C46.3722 32.5121 46.8647 32.6389 47.3395 32.81C50.7573 34.0043 53.2109 37.2599 53.2109 41.0818V42.341H32.5682V39.8934C32.5682 36.85 34.373 34.2196 36.9681 33.0164C37.2747 32.8719 37.5903 32.751 37.9176 32.6507C38.2626 32.5446 38.6165 32.462 38.9792 32.406C39.3596 32.3441 39.7519 32.3146 40.15 32.3146H41.7011V30.8401H40.15C39.0235 30.8401 37.9442 31.0465 36.9474 31.421C36.5965 31.5567 36.2544 31.71 35.9271 31.884C35.6086 32.0521 35.3019 32.2379 35.007 32.4414C32.6419 34.0721 31.0938 36.8029 31.0938 39.8934V43.8155H54.6853V41.0818C54.6853 36.4283 51.586 32.5033 47.3395 31.2559Z" fill="#3C329B" />
            <path d="M11.9168 34.1961V32.3205C11.4095 32.3323 10.9171 32.3972 10.4423 32.5063V35.6705H14.1491C14.4263 35.1456 14.7507 34.6531 15.1223 34.1961H11.9168ZM11.9168 34.1961V32.3205C11.4095 32.3323 10.9171 32.3972 10.4423 32.5063V35.6705H14.1491C14.4263 35.1456 14.7507 34.6531 15.1223 34.1961H11.9168ZM11.9168 34.1961V32.3205C11.4095 32.3323 10.9171 32.3972 10.4423 32.5063V35.6705H14.1491C14.4263 35.1456 14.7507 34.6531 15.1223 34.1961H11.9168ZM22.9252 25.0455L21.2354 22.9753C21.2384 22.875 21.2413 22.7748 21.2413 22.6745C21.2413 19.3039 18.5106 16.5702 15.14 16.5702C11.7693 16.5702 9.03564 19.3039 9.03564 22.6745C9.03564 24.1578 9.56351 25.5143 10.4423 26.573V35.6705H17.5552V30.2887H19.4307C20.4304 30.2887 21.2413 29.4777 21.2413 28.4751V26.1277H22.4091C22.9694 26.1277 23.2791 25.479 22.9252 25.0455ZM19.7669 24.6533V28.4751C19.7669 28.6638 19.6165 28.8142 19.4307 28.8142H16.0807V34.1961H11.9168V26.0393L11.5776 25.6294C10.8905 24.8037 10.5101 23.7538 10.5101 22.6745C10.5101 20.1237 12.5862 18.0447 15.14 18.0447C17.6938 18.0447 19.7669 20.1237 19.7669 22.6745C19.7669 22.7512 19.7669 22.8279 19.761 22.9045L19.7344 23.4707L20.0912 23.9072L20.7017 24.6533H19.7669ZM11.9168 34.1961V32.3205C11.4095 32.3323 10.9171 32.3972 10.4423 32.5063V35.6705H14.1491C14.4263 35.1456 14.7507 34.6531 15.1223 34.1961H11.9168ZM11.9168 34.1961V32.3205C11.4095 32.3323 10.9171 32.3972 10.4423 32.5063V35.6705H14.1491C14.4263 35.1456 14.7507 34.6531 15.1223 34.1961H11.9168Z" fill="#3C329B" />
            <path d="M52.7094 25.0455L51.0196 22.9753C51.0226 22.875 51.0255 22.7748 51.0255 22.6745C51.0255 19.3039 48.2948 16.5702 44.9241 16.5702C41.5535 16.5702 38.8198 19.3039 38.8198 22.6745C38.8198 24.1578 39.3477 25.5143 40.2265 26.573V35.6705H47.3393V30.2887H49.2149C50.2146 30.2887 51.0255 29.4777 51.0255 28.4751V26.1277H52.1933C52.7536 26.1277 53.0632 25.479 52.7094 25.0455ZM49.551 24.6533V28.4751C49.551 28.6638 49.4006 28.8142 49.2149 28.8142H45.8649V34.1961H41.7009V26.0393L41.3618 25.6294C40.6747 24.8037 40.2943 23.7538 40.2943 22.6745C40.2943 20.1237 42.3704 18.0447 44.9241 18.0447C47.4779 18.0447 49.551 20.1237 49.551 22.6745C49.551 22.7512 49.551 22.8279 49.5451 22.9045L49.5186 23.4707L49.8754 23.9072L50.4859 24.6533H49.551Z" fill="#3C329B" />
            <path d="M43.0369 41.0819V43.0783H13.8423V39.8934C13.8423 38.3511 14.264 36.9091 14.9983 35.6705C15.6382 34.5912 16.514 33.6682 17.555 32.9722C17.7998 32.8071 18.0563 32.6567 18.3188 32.5211C18.6638 32.3382 19.0236 32.1819 19.3952 32.0492H19.3981C19.7874 31.9106 20.1884 31.8015 20.6042 31.7248C21.1085 31.6275 21.6275 31.5774 22.1613 31.5774H25.0365V33.2347H30.5097V31.5774H33.5324C34.3581 31.5774 35.1602 31.6836 35.927 31.8841C36.6259 32.064 37.2923 32.3235 37.9175 32.6508C38.7727 33.0961 39.5483 33.6682 40.2265 34.3406C40.6394 34.7475 41.0109 35.1928 41.3442 35.6705C42.4117 37.2069 43.0369 39.0707 43.0369 41.0819Z" fill="#BEBBFF" />
            <path d="M42.229 35.6705C41.6835 34.7917 41.0052 34.0014 40.2267 33.329C39.8345 32.9899 39.4187 32.6832 38.9793 32.406C38.3453 32.0079 37.6641 31.6746 36.9475 31.421C35.88 31.0465 34.7299 30.8401 33.5326 30.8401H30.5099V32.3146H33.5326C34.0339 32.3146 34.5293 32.3558 35.0071 32.4414C35.6912 32.5534 36.3488 32.751 36.9681 33.0164C38.2362 33.5561 39.3479 34.3877 40.2267 35.4257C40.2945 35.5053 40.3623 35.5879 40.4243 35.6705C41.6009 37.1597 42.2998 39.0411 42.2998 41.0818V42.341H14.5797V39.8934C14.5797 38.3334 15.0545 36.8795 15.8684 35.6705C16.3284 34.9863 16.9005 34.3788 17.5552 33.8805C18.1125 33.4499 18.7348 33.096 19.4012 32.8365C19.7551 32.695 20.1267 32.58 20.5071 32.4974C20.9111 32.406 21.3239 32.3499 21.7486 32.3264C21.8842 32.3175 22.0228 32.3146 22.1614 32.3146H25.0367V30.8401H22.1614C21.1942 30.8401 20.2594 30.9934 19.3865 31.2736C18.9795 31.4033 18.5873 31.5626 18.2099 31.7454C17.9857 31.8545 17.7675 31.9695 17.5552 32.0993C17.4195 32.176 17.2868 32.2615 17.1571 32.347C16.7767 32.5977 16.4169 32.8778 16.0807 33.1816C15.7357 33.4971 15.4172 33.8333 15.1223 34.196C14.7507 34.6531 14.4264 35.1456 14.1492 35.6705C13.4827 36.9297 13.1052 38.3688 13.1052 39.8934V43.8155H43.7743V41.0818C43.7743 39.0942 43.2081 37.2393 42.229 35.6705Z" fill="#3C329B" />
            <path d="M38.3452 22.123L36.3458 19.6725C36.3517 19.5545 36.3547 19.4365 36.3547 19.3156C36.3547 15.3228 33.1167 12.0878 29.1239 12.0878C25.131 12.0878 21.896 15.3228 21.896 19.3156C21.896 21.0732 22.5212 22.6804 23.5622 23.9337V34.7091H31.9843V28.3335H34.2079C35.3933 28.3335 36.3547 27.3751 36.3547 26.1896V23.4088H37.7377C38.4013 23.4088 38.7669 22.6362 38.3452 22.123ZM34.8802 21.9343V26.1896C34.8802 26.5583 34.5794 26.859 34.2079 26.859H30.5099V33.2347H25.0366V23.3999L24.6975 22.99C23.8394 21.9608 23.3705 20.6574 23.3705 19.3156C23.3705 16.1426 25.9508 13.5622 29.1239 13.5622C32.2969 13.5622 34.8802 16.1426 34.8802 19.3156C34.8802 19.413 34.8773 19.5073 34.8714 19.6017L34.8448 20.1679L35.2046 20.6043L36.2869 21.9343H34.8802Z" fill="#3C329B" />
        </svg>

    )
}

export function Intelligence(props) {

    return (
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M41.9428 33.8215H40.8222V21.0289H40.8163C40.7396 15.4171 36.7173 10.7548 31.3856 9.67548V8.51065C31.3856 7.20721 30.3269 6.15149 29.0264 6.15149C27.7259 6.15149 26.6673 7.20721 26.6673 8.51065V9.67548C21.3326 10.7548 17.3103 15.4171 17.2336 21.0289H17.2306V33.8215H16.1071C14.7712 33.8215 13.6919 34.9037 13.6919 36.2396V38.6253C13.6919 39.9582 14.7712 41.0405 16.1071 41.0405H24.0191C24.0663 43.7653 26.2898 45.9593 29.0264 45.9593C31.763 45.9593 33.9836 43.7653 34.0308 41.0405H41.9428C43.2787 41.0405 44.361 39.9582 44.361 38.6253V36.2396C44.361 34.9037 43.2787 33.8215 41.9428 33.8215ZM28.1417 8.51065C28.1417 8.02407 28.5369 7.62596 29.0264 7.62596C29.5159 7.62596 29.9111 8.02407 29.9111 8.51065V9.472C29.6192 9.45136 29.3243 9.43957 29.0264 9.43957C28.7286 9.43957 28.4337 9.45136 28.1417 9.472V8.51065ZM18.7051 21.3386V21.0496C18.7464 18.3336 19.8346 15.7886 21.775 13.8807C23.1462 12.5301 24.8301 11.61 26.6673 11.1824C27.1479 11.0703 27.6434 10.9937 28.1417 10.9524C28.4337 10.9258 28.7286 10.914 29.0264 10.914C29.3243 10.914 29.6192 10.9258 29.9111 10.9524C30.4095 10.9937 30.9049 11.0703 31.3856 11.1824C33.2198 11.61 34.9066 12.5301 36.2779 13.8807C38.2153 15.7886 39.3065 18.3336 39.3418 21.0496L39.3477 21.2884V33.6622H18.7051V21.3386ZM29.0264 44.4849C27.1067 44.4849 25.5408 42.9485 25.4936 41.0405H32.5563C32.5091 42.9485 30.9432 44.4849 29.0264 44.4849ZM42.8865 38.6253C42.8865 39.1443 42.4618 39.566 41.9428 39.566H16.1071C15.5881 39.566 15.1664 39.1443 15.1664 38.6253V36.2396C15.1664 35.7206 15.5881 35.2959 16.1071 35.2959H41.9428C42.4618 35.2959 42.8865 35.7206 42.8865 36.2396V38.6253Z" fill="#3C329B" />
            <path d="M42.8866 36.2396V38.6253C42.8866 39.1443 42.462 39.566 41.9429 39.566H16.1072C15.5882 39.566 15.1665 39.1443 15.1665 38.6253V36.2396C15.1665 35.7205 15.5882 35.2959 16.1072 35.2959H41.9429C42.462 35.2959 42.8866 35.7205 42.8866 36.2396Z" fill="#BEBBFF" />
            <path d="M39.3418 21.0496C39.3064 18.3336 38.2153 15.7887 36.2779 13.8807C34.9066 12.5301 33.2198 11.61 31.3856 11.1824C30.9049 11.0704 30.4095 10.9937 29.9111 10.9524C29.6191 10.9259 29.3242 10.9141 29.0264 10.9141C28.7286 10.9141 28.4337 10.9259 28.1417 10.9524C27.6433 10.9937 27.1479 11.0704 26.6672 11.1824C24.83 11.61 23.1462 12.5301 21.7749 13.8807C19.8345 15.7887 18.7464 18.3336 18.708 21.0496V21.3386L18.7051 33.6622H39.3477V21.2885L39.3418 21.0496ZM29.0146 30.9227C25.3137 29.1622 23.0577 25.9124 23.1285 18.0446L29.0146 16.7677L34.9036 18.1508C35.172 26.1306 32.8187 29.2211 29.0146 30.9227Z" fill="white" />
            <path d="M29.9112 8.51066V9.47202C29.6193 9.45137 29.3244 9.43958 29.0265 9.43958C28.7287 9.43958 28.4338 9.45137 28.1418 9.47202V8.51066C28.1418 8.02408 28.537 7.62598 29.0265 7.62598C29.5161 7.62598 29.9112 8.02408 29.9112 8.51066Z" fill="#BEBBFF" />
            <path d="M32.5564 41.0405C32.5092 42.9485 30.9433 44.4849 29.0265 44.4849C27.1097 44.4849 25.5408 42.9485 25.4937 41.0405H32.5564Z" fill="#BEBBFF" />
            <path d="M29.7638 46.7644H28.2893V50.8281H29.7638V46.7644Z" fill="#3C329B" />
            <path d="M36.2074 44.6525L35.1409 45.6707L37.9468 48.61L39.0134 47.5919L36.2074 44.6525Z" fill="#3C329B" />
            <path d="M21.846 44.9253L19.0396 47.8641L20.1059 48.8825L22.9124 45.9436L21.846 44.9253Z" fill="#3C329B" />
            <path d="M34.9037 18.1508L29.0147 16.7677L23.1286 18.0446C23.0578 25.9124 25.3137 29.1621 29.0147 30.9226C32.8188 29.2211 35.1721 26.1306 34.9037 18.1508ZM29.0353 29.2771C26.7528 28.012 24.7652 25.703 24.6119 19.233L29.0029 18.2805L33.4499 19.3244C33.4617 25.4582 31.713 27.8793 29.0353 29.2771Z" fill="#3C329B" />
            <path d="M29.0028 18.2805L24.6118 19.233C24.7652 25.703 26.7528 28.012 29.0352 29.2771C31.7129 27.8793 33.4616 25.4583 33.4498 19.3244L29.0028 18.2805ZM29.0205 27.0654C27.1833 26.1925 26.0627 24.5795 26.0981 20.6751L29.0205 20.041L31.9429 20.7281C32.0756 24.6886 30.9078 26.222 29.0205 27.0654Z" fill="#BEBBFF" />
            <path d="M31.943 20.7281L29.0206 20.041L26.0982 20.675C26.0628 24.5794 27.1834 26.1925 29.0206 27.0654C30.908 26.222 32.0757 24.6886 31.943 20.7281ZM29.0442 25.3816C28.3542 24.8861 27.7467 23.9867 27.6051 21.8576L29.0088 21.5538L30.4656 21.8959C30.3771 24.0221 29.7726 24.892 29.0442 25.3816Z" fill="#3C329B" />
            <path d="M30.4657 21.8959C30.3772 24.0221 29.7727 24.892 29.0443 25.3816C28.3543 24.8861 27.7468 23.9867 27.6052 21.8576L29.0089 21.5538L30.4657 21.8959Z" fill="white" />
        </svg>

    )
}

export function IncidentRequest(props) {
    return (
        <svg width="37" height="50" viewBox="0 0 37 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M32.6732 4.43433H17.4079C16.9362 4.43433 16.5536 4.81977 16.5536 5.29151V14.801C16.5536 15.2728 16.9362 15.6582 17.4079 15.6582H20.2872V21.4054L22.0217 19.6766L23.4599 18.2441L24.6105 17.0964L26.0487 15.6582H32.6732C33.1449 15.6582 33.5275 15.2728 33.5275 14.801V5.29151C33.5275 4.81977 33.1449 4.43433 32.6732 4.43433ZM19.7206 11.4385C18.9151 11.4385 18.2651 10.7855 18.2651 9.98299C18.2651 9.18046 18.9151 8.52463 19.7206 8.52463C20.526 8.52463 21.176 9.17759 21.176 9.98299C21.176 10.7884 20.526 11.4385 19.7206 11.4385ZM25.0218 11.4385C24.2193 11.4385 23.5664 10.7855 23.5664 9.98299C23.5664 9.18046 24.2193 8.52463 25.0218 8.52463C25.8244 8.52463 26.4802 9.17759 26.4802 9.98299C26.4802 10.7884 25.8273 11.4385 25.0218 11.4385ZM30.326 11.4385C29.5206 11.4385 28.8677 10.7855 28.8677 9.98299C28.8677 9.18046 29.5206 8.52463 30.326 8.52463C31.1314 8.52463 31.7815 9.17759 31.7815 9.98299C31.7815 10.7884 31.1285 11.4385 30.326 11.4385Z" fill="#BEBBFF" />
            <path d="M22.022 19.6764V44.2039C22.022 44.7993 21.5388 45.2796 20.9462 45.2796H5.01072C4.41817 45.2796 3.93493 44.7993 3.93493 44.2039V10.2014C3.93493 9.60597 4.41817 9.1256 5.01072 9.1256H16.5539V7.68738H5.01072C3.62139 7.68738 2.4967 8.81207 2.4967 10.2014V44.2039C2.4967 45.5932 3.62139 46.7179 5.01072 46.7179H20.9462C22.3355 46.7179 23.4602 45.5932 23.4602 44.2039V18.2439L22.022 19.6764Z" fill="#3C329B" />
            <path d="M22.0214 41.0026V44.204C22.0214 44.7995 21.5382 45.2798 20.9456 45.2798H5.01012C4.41757 45.2798 3.93433 44.7995 3.93433 44.204V41.0026H22.0214Z" fill="#BEBBFF" />
            <path d="M22.689 39.5642H3.62683V41.0024H22.689V39.5642Z" fill="#3C329B" />
            <path d="M16.5533 11.9937H3.93433V13.4319H16.5533V11.9937Z" fill="#3C329B" />
            <path d="M17.4084 15.6581C16.9367 15.6581 16.5541 15.2726 16.5541 14.8009V9.12568H15.1159V14.8009C15.1159 16.0694 16.1428 17.0963 17.4084 17.0963H18.8495V15.6581H17.4084ZM32.6737 2.99597H17.4084C16.1428 2.99597 15.1159 4.02286 15.1159 5.29138V14.8009C15.1159 16.0694 16.1428 17.0963 17.4084 17.0963H18.8495V15.6581H17.4084C16.9367 15.6581 16.5541 15.2726 16.5541 14.8009V5.29138C16.5541 4.81964 16.9367 4.43419 17.4084 4.43419H32.6737C33.1455 4.43419 33.528 4.81964 33.528 5.29138V14.8009C33.528 15.2726 33.1455 15.6581 32.6737 15.6581H26.6246V17.0963H32.6737C33.9394 17.0963 34.9663 16.0694 34.9663 14.8009V5.29138C34.9663 4.02286 33.9394 2.99597 32.6737 2.99597Z" fill="#3C329B" />
            <path d="M15.1151 9.12573V11.9935H3.93433V10.2015C3.93433 9.6061 4.41757 9.12573 5.01012 9.12573H15.1151Z" fill="#BEBBFF" />
            <path d="M26.049 15.658L24.6108 17.0962L23.4602 18.2439L22.022 19.6764L20.2875 21.4051V15.658H18.8492V24.8712L21.3029 22.4234L22.022 21.7071L23.4602 20.2718L25.6261 18.1144L26.6473 17.0962L28.0884 15.658H26.049Z" fill="#3C329B" />
            <path d="M13.1047 19.1099L6.20123 32.9111H20.0082L13.1047 19.1099ZM13.1047 22.3229L17.6811 31.4728H8.52828L13.1047 22.3229Z" fill="#3C329B" />
            <path d="M13.1048 22.3226L8.52838 31.4726H17.6812L13.1048 22.3226ZM13.5765 30.7794H12.1383V29.6346H13.5765V30.7794ZM12.1383 28.6249V25.3947H13.5765V28.6249H12.1383Z" fill="white" />
            <path d="M13.5763 25.3947H12.1381V28.6249H13.5763V25.3947Z" fill="#3C329B" />
            <path d="M13.5763 29.6346H12.1381V30.7795H13.5763V29.6346Z" fill="#3C329B" />
            <path d="M19.7206 12.1574C18.5211 12.1574 17.546 11.1822 17.546 9.98277C17.546 8.78329 18.5211 7.8053 19.7206 7.8053C20.9201 7.8053 21.8952 8.78329 21.8952 9.98277C21.8952 11.1822 20.9201 12.1574 19.7206 12.1574ZM19.7206 9.24352C19.315 9.24352 18.9842 9.57431 18.9842 9.98277C18.9842 10.3912 19.315 10.7191 19.7206 10.7191C20.1262 10.7191 20.457 10.3883 20.457 9.98277C20.457 9.57719 20.1262 9.24352 19.7206 9.24352Z" fill="#3C329B" />
            <path d="M25.0222 12.1574C23.8227 12.1574 22.8476 11.1822 22.8476 9.98277C22.8476 8.78329 23.8227 7.8053 25.0222 7.8053C26.2217 7.8053 27.1997 8.78329 27.1997 9.98277C27.1997 11.1822 26.2217 12.1574 25.0222 12.1574ZM25.0222 9.24352C24.6166 9.24352 24.2858 9.57431 24.2858 9.98277C24.2858 10.3912 24.6166 10.7191 25.0222 10.7191C25.4278 10.7191 25.7614 10.3883 25.7614 9.98277C25.7614 9.57719 25.4306 9.24352 25.0222 9.24352Z" fill="#3C329B" />
            <path d="M30.326 12.1574C29.1265 12.1574 28.1485 11.1822 28.1485 9.98277C28.1485 8.78329 29.1265 7.8053 30.326 7.8053C31.5254 7.8053 32.5006 8.78329 32.5006 9.98277C32.5006 11.1822 31.5254 12.1574 30.326 12.1574ZM30.326 9.24352C29.9175 9.24352 29.5867 9.57431 29.5867 9.98277C29.5867 10.3912 29.9175 10.7191 30.326 10.7191C30.7344 10.7191 31.0623 10.3883 31.0623 9.98277C31.0623 9.57719 30.7315 9.24352 30.326 9.24352Z" fill="#3C329B" />
        </svg>

    )
}

export function IncidentVerification(props) {
    return (
        <svg width="40" height="47" viewBox="0 0 40 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M27.65 13.9314V41.1512C27.65 41.7467 27.1667 42.2299 26.5742 42.2299H10.6387C10.0461 42.2299 9.56289 41.7467 9.56289 41.1512V7.14878C9.56289 6.55623 10.0461 6.07299 10.6387 6.07299H24.483C24.6355 5.56386 24.8483 5.08349 25.1158 4.63477H10.6387C9.24935 4.63477 8.12466 5.75946 8.12466 7.14878V41.1512C8.12466 42.5406 9.24935 43.6681 10.6387 43.6681H26.5742C27.9635 43.6681 29.0882 42.5406 29.0882 41.1512V14.4895C28.5819 14.36 28.0987 14.1731 27.65 13.9314Z" fill="#3C329B" />
            <path d="M27.6501 37.9526V41.1512C27.6501 41.7467 27.1668 42.2299 26.5743 42.2299H10.6388C10.0462 42.2299 9.56299 41.7467 9.56299 41.1512V37.9526H27.6501Z" fill="#BEBBFF" />
            <path d="M22.996 6.07324C22.8522 6.66291 22.7774 7.28135 22.7774 7.91417V8.06087H9.56299V7.14903C9.56299 6.55648 10.0462 6.07324 10.6388 6.07324H22.996Z" fill="#BEBBFF" />
            <path d="M24.2152 8.22775C24.2152 8.66209 24.2584 9.0878 24.3418 9.49914H9.25485V8.06091H24.2152V8.22775Z" fill="#3C329B" />
            <path d="M28.3171 36.5144H9.25485V37.9526H28.3171V36.5144Z" fill="#3C329B" />
            <path d="M22.9957 6.07299C22.8519 6.66266 22.7771 7.28109 22.7771 7.91391V8.06061H24.2153V7.91391C24.2153 7.27534 24.3102 6.6569 24.4828 6.07299H22.9957ZM30.9893 0.0180664H30.6758C27.4829 0.0180664 24.7331 1.91077 23.4904 4.63476C23.2776 5.09212 23.1136 5.57248 22.9957 6.07299C22.8519 6.66266 22.7771 7.28109 22.7771 7.91391V8.22744C22.7771 8.66179 22.8116 9.08462 22.8806 9.49883C23.3207 12.2315 25.1645 14.4952 27.6498 15.525C28.1071 15.7177 28.5904 15.8644 29.088 15.9651C29.6 16.0715 30.1321 16.1262 30.6758 16.1262H30.9893C35.35 16.1262 38.8852 12.5881 38.8852 8.22744V7.91391C38.8852 3.55322 35.35 0.0180664 30.9893 0.0180664ZM37.4469 8.22744C37.4469 11.7885 34.5504 14.6879 30.9893 14.6879H30.6758C30.1264 14.6879 29.5942 14.6189 29.088 14.4895C28.5817 14.36 28.0985 14.1731 27.6498 13.9314C25.967 13.0369 24.7273 11.4203 24.3419 9.49883C24.2584 9.0875 24.2153 8.66179 24.2153 8.22744V7.91391C24.2153 7.27534 24.3102 6.6569 24.4828 6.07299C24.6353 5.56385 24.8481 5.08349 25.1156 4.63476C26.2374 2.73343 28.3113 1.45629 30.6758 1.45629H30.9893C34.5504 1.45629 37.4469 4.35287 37.4469 7.91391V8.22744ZM22.9957 6.07299C22.8519 6.66266 22.7771 7.28109 22.7771 7.91391V8.06061H24.2153V7.91391C24.2153 7.27534 24.3102 6.6569 24.4828 6.07299H22.9957Z" fill="#3C329B" />
            <path d="M37.447 7.91416V8.2277C37.447 11.7887 34.5505 14.6882 30.9894 14.6882H30.6759C30.1265 14.6882 29.5943 14.6192 29.0881 14.4897C28.5818 14.3603 28.0986 14.1733 27.6499 13.9317C25.9671 13.0371 24.7274 11.4206 24.342 9.49909C24.2585 9.08775 24.2154 8.66204 24.2154 8.2277V7.91416C24.2154 7.27559 24.3103 6.65716 24.4829 6.07324C24.6354 5.56411 24.8482 5.08374 25.1157 4.63502C26.2375 2.73368 28.3114 1.45654 30.6759 1.45654H30.9894C34.5505 1.45654 37.447 4.35312 37.447 7.91416Z" fill="#BEBBFF" />
            <path d="M18.7327 16.0574L11.8292 29.8614H25.6362L18.7327 16.0574ZM18.7327 19.2732L23.3091 28.4232H14.1563L18.7327 19.2732Z" fill="#3C329B" />
            <path d="M18.7326 19.2732L14.1561 28.4232H23.309L18.7326 19.2732ZM19.2043 27.7271H17.7661V26.5822H19.2043V27.7271ZM17.7661 25.5726V22.3424H19.2043V25.5726H17.7661Z" fill="white" />
            <path d="M19.2043 22.342H17.7661V25.5723H19.2043V22.342Z" fill="#3C329B" />
            <path d="M19.2043 26.582H17.7661V27.7269H19.2043V26.582Z" fill="#3C329B" />
            <path d="M29.9677 11.3172L27.1574 8.50977L28.1756 7.49151L29.9677 9.28353L34.1443 5.10693L35.1625 6.12232L29.9677 11.3172Z" fill="#3C329B" />
        </svg>

    )
}

export function ResponseDispatch(props) {
    return (
        <svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4.03757 31.5454V37.2092C4.03757 37.9254 4.61861 38.5064 5.33484 38.5064H32.6553C33.3716 38.5064 33.9526 37.9254 33.9526 37.2092V31.5454C33.9526 30.8292 33.3716 30.2482 32.6553 30.2482H5.33484C4.61861 30.2482 4.03757 30.8292 4.03757 31.5454ZM32.011 37.0682H5.97629C5.70015 37.0682 5.47291 36.8439 5.47291 36.5648V32.1869C5.47291 31.9108 5.69728 31.6835 5.97629 31.6835H32.011C32.2871 31.6835 32.5144 31.9079 32.5144 32.1869V36.5648C32.5144 36.841 32.29 37.0682 32.011 37.0682Z" fill="#3C329B" />
            <path d="M18.9925 10.6338C12.5752 10.6338 7.37454 15.8373 7.37454 22.2518V31.6865H8.81277V22.2518C8.81277 16.6398 13.3777 12.072 18.9925 12.072C24.6073 12.072 29.1722 16.6398 29.1722 22.2518V31.6865H30.6105V22.2518C30.6105 15.8373 25.4099 10.6338 18.9925 10.6338Z" fill="#3C329B" />
            <path d="M18.9921 12.0718C13.3773 12.0718 8.81235 16.6396 8.81235 22.2515V30.248H29.1718V22.2515C29.1718 16.6396 24.604 12.0718 18.9921 12.0718ZM25.3864 23.9256H21.322V27.9871H16.7139V23.9256H12.6524V19.3175H16.7139V15.2531H21.322V19.3175H25.3864V23.9256Z" fill="#BEBBFF" />
            <path d="M19.7116 3.79919H18.2734V9.55209H19.7116V3.79919Z" fill="#3C329B" />
            <path d="M5.38752 7.3427L4.37054 8.35968L8.43845 12.4276L9.45543 11.4106L5.38752 7.3427Z" fill="#3C329B" />
            <path d="M33.1762 7.31344L29.1083 11.3813L30.1253 12.3983L34.1932 8.33042L33.1762 7.31344Z" fill="#3C329B" />
            <path d="M19.8841 22.4873V26.5488H18.1525V22.4873H16.7143V27.987H21.3223V22.4873H19.8841ZM16.7143 15.253V20.7556H18.1525V16.6912H19.8841V20.7556H21.3223V15.253H16.7143Z" fill="#3C329B" />
            <path d="M18.1524 20.7559V19.3177H12.6526V23.9258H18.1524V22.4875H14.0908V20.7559H18.1524ZM19.884 19.3177V20.7559H23.9484V22.4875H19.884V23.9258H25.3866V19.3177H19.884Z" fill="#3C329B" />
            <path d="M32.5142 33.6567H5.32312V35.0949H32.5142V33.6567Z" fill="#3C329B" />
        </svg>

    )
}

export function RealtimeSharing(props) {
    return (
        <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.36145 13.1289V34.4146C8.88784 34.4146 9.36533 34.4462 9.79967 34.5009V13.0397C9.36533 13.0972 8.88784 13.1289 8.36145 13.1289ZM12.6589 37.291V38.7292H33.9532V37.291H12.6589ZM9.79967 36.4655C9.36533 36.523 8.88784 36.5547 8.36145 36.5547V37.9929C9.90898 37.9929 11.3184 37.7254 12.3511 37.291H9.79967V36.4655ZM38.2679 36.5547C37.7472 36.5547 37.2697 36.523 36.8383 36.4684V37.291H34.2782C35.3109 37.7254 36.7175 37.9929 38.2679 37.9929H38.2765V36.5547H38.2679ZM38.2679 13.1289C37.7472 13.1289 37.2697 13.0972 36.8383 13.0426V34.5009C37.2697 34.4462 37.7472 34.4146 38.2679 34.4146H38.2765V13.1289H38.2679ZM12.6589 8.81421V10.2524H33.9532V8.81421H12.6589Z" fill="#3C329B" />
            <path d="M44.0031 5.75351C44.0146 5.6931 44.0204 5.6327 44.0204 5.56942C44.0204 5.50614 44.0146 5.44573 44.0031 5.38533V5.36231C43.7615 4.07367 41.2877 3.06116 38.2703 3.06116C35.253 3.06116 32.7792 4.07367 32.5376 5.36231H32.5175V12.0587C32.5175 13.2294 34.3555 14.2131 36.8408 14.4864C37.2981 14.5411 37.7756 14.5669 38.2703 14.5669H38.279C41.4546 14.5641 44.0232 13.4451 44.0232 12.0587C44.0232 11.9954 44.0175 11.935 44.006 11.8746V5.75351H44.0031ZM34.2865 5.36231C34.8963 4.97975 36.2482 4.49938 38.2675 4.49938C40.2867 4.49938 41.6387 4.97975 42.2485 5.36231C42.3664 5.4371 42.4585 5.50614 42.5189 5.56942C42.1392 5.95486 40.6779 6.63946 38.2675 6.63946C35.857 6.63946 34.3958 5.95486 34.0161 5.56942C34.0765 5.50614 34.1685 5.4371 34.2865 5.36231ZM42.5649 10.3903C42.5649 10.3903 42.5361 10.376 42.5189 10.3702V12.0587C42.1392 12.4441 40.6808 13.1258 38.2761 13.1287H38.2675C37.7468 13.1287 37.2693 13.0971 36.8379 13.0424C35.276 12.8468 34.3124 12.3607 34.0161 12.0587H33.9528V7.22913C35.0056 7.74976 36.5474 8.07768 38.2675 8.07768C39.9876 8.07768 41.5121 7.75264 42.5649 7.23776V10.3903Z" fill="#3C329B" />
            <path d="M44.0031 35.6684C44.0146 35.608 44.0204 35.5476 44.0204 35.4843C44.0204 35.4211 44.0146 35.3606 44.0031 35.3002V35.2772C43.7615 33.9886 41.2935 32.979 38.279 32.9761H38.2703C37.7756 32.9761 37.2981 33.002 36.8408 33.0537C34.5051 33.3155 32.7389 34.1986 32.5376 35.2772H32.5175V41.9736C32.5175 43.36 35.0919 44.4819 38.2703 44.4819C41.4488 44.4819 44.0232 43.36 44.0232 41.9736C44.0232 41.9103 44.0175 41.8499 44.006 41.7895V35.6684H44.0031ZM34.2865 35.2772C34.7381 34.9925 35.5981 34.6559 36.8379 34.5006C37.2693 34.4459 37.7468 34.4143 38.2675 34.4143H38.2761C40.2896 34.4143 41.6387 34.8947 42.2485 35.2772C42.3664 35.352 42.4585 35.4211 42.5189 35.4843C42.1392 35.8698 40.6808 36.5515 38.2761 36.5544H38.2675C37.7468 36.5544 37.2693 36.5227 36.8379 36.4681C35.276 36.2725 34.3124 35.7864 34.0161 35.4843C34.0765 35.4211 34.1685 35.352 34.2865 35.2772ZM42.5649 40.3053C42.5649 40.3053 42.5361 40.2909 42.5189 40.2851V41.9736C42.1392 42.359 40.6779 43.0436 38.2675 43.0436C35.857 43.0436 34.3958 42.359 34.0161 41.9736H33.9528V37.144C34.0564 37.1958 34.1657 37.2447 34.2778 37.2907C35.3105 37.7251 36.7171 37.9926 38.2675 37.9926H38.2761C39.9818 37.9926 41.515 37.6676 42.5649 37.1527V40.3053Z" fill="#3C329B" />
            <path d="M14.0973 5.75351C14.1088 5.6931 14.1145 5.6327 14.1145 5.56942C14.1145 5.50614 14.1088 5.44573 14.0973 5.38533V5.36231H14.0944C13.8528 4.07367 11.379 3.06116 8.36166 3.06116C5.34426 3.06116 2.87052 4.07367 2.6289 5.36231H2.60876V12.0587C2.60876 13.4451 5.18318 14.5669 8.36166 14.5669C8.8564 14.5669 9.33965 14.5382 9.79988 14.4864C12.2823 14.2103 14.1145 13.2294 14.1145 12.0587C14.1145 11.9954 14.1088 11.935 14.0973 11.8746V5.75351ZM4.38066 5.36231C4.99046 4.97975 6.34239 4.49938 8.36166 4.49938C10.3809 4.49938 11.7329 4.97975 12.3427 5.36231C12.4606 5.4371 12.5526 5.50614 12.613 5.56942C12.2334 5.95486 10.7721 6.63946 8.36166 6.63946C5.95119 6.63946 4.48996 5.95486 4.11027 5.56942C4.17067 5.50614 4.26272 5.4371 4.38066 5.36231ZM12.6591 10.3903C12.6591 10.3903 12.6303 10.376 12.613 10.3702V12.0587C12.3168 12.3607 11.356 12.8439 9.79988 13.0395C9.36554 13.0971 8.88805 13.1287 8.36166 13.1287C5.95119 13.1287 4.48996 12.4441 4.11027 12.0587H4.04699V7.22913C5.09977 7.74976 6.64154 8.07768 8.36166 8.07768C10.0818 8.07768 11.6063 7.75264 12.6591 7.23776V10.3903Z" fill="#3C329B" />
            <path d="M14.0973 35.6684C14.1088 35.608 14.1145 35.5476 14.1145 35.4843C14.1145 35.4211 14.1088 35.3606 14.0973 35.3002V35.2772H14.0944C13.8931 34.2014 12.1327 33.3184 9.79988 33.0566C9.33965 33.0048 8.85928 32.9761 8.36166 32.9761C5.34426 32.9761 2.87052 33.9886 2.6289 35.2772H2.60876V41.9736C2.60876 43.36 5.18318 44.4819 8.36166 44.4819C8.8564 44.4819 9.33965 44.4531 9.79988 44.4013C12.2823 44.1252 14.1145 43.1443 14.1145 41.9736C14.1145 41.9103 14.1088 41.8499 14.0973 41.7895V35.6684ZM4.38066 35.2772C4.99046 34.8947 6.34239 34.4143 8.36166 34.4143C8.88805 34.4143 9.36554 34.4459 9.79988 34.5006C11.0339 34.6588 11.8911 34.9953 12.3427 35.2772C12.4606 35.352 12.5526 35.4211 12.613 35.4843C12.3168 35.7864 11.356 36.2696 9.79988 36.4652C9.36554 36.5227 8.88805 36.5544 8.36166 36.5544C5.95119 36.5544 4.48996 35.8698 4.11027 35.4843C4.17067 35.4211 4.26272 35.352 4.38066 35.2772ZM12.6591 40.3053C12.6591 40.3053 12.6303 40.2909 12.613 40.2851V41.9736C12.3168 42.2756 11.356 42.7589 9.79988 42.9545C9.36554 43.012 8.88805 43.0436 8.36166 43.0436C5.95119 43.0436 4.48996 42.359 4.11027 41.9736H4.04699V37.144C5.09977 37.6647 6.64154 37.9926 8.36166 37.9926C9.90918 37.9926 11.3186 37.7251 12.3513 37.2907C12.4577 37.2476 12.5613 37.2016 12.6591 37.1527V40.3053Z" fill="#3C329B" />
            <path d="M29.0718 24.0476C29.0718 23.9872 29.0661 23.9239 29.0546 23.8635V19.4194C29.0661 19.359 29.0718 19.2986 29.0718 19.2353C29.0718 19.172 29.0661 19.1116 29.0546 19.0512V19.0282H29.0517C28.8101 17.7424 26.3334 16.7271 23.3189 16.7271C20.3044 16.7271 17.8278 17.7424 17.5862 19.0282H17.566V28.601C17.566 29.2367 18.1097 29.8178 19.0043 30.2607V30.255C19.5249 30.511 20.1663 30.7238 20.8912 30.8705H20.8941C21.6305 31.0201 22.4531 31.1035 23.3189 31.1035C24.1847 31.1035 24.993 31.0201 25.7236 30.8734C26.4485 30.7296 27.0928 30.5196 27.6163 30.2636V30.2694C28.4333 29.8695 28.9625 29.3547 29.0546 28.7851V28.7794C29.0661 28.719 29.0718 28.6585 29.0718 28.5953C29.0718 28.532 29.0661 28.4716 29.0546 28.4112V24.2317C29.0661 24.1713 29.0718 24.1109 29.0718 24.0476ZM19.3379 19.0282C19.9477 18.6456 21.2997 18.1653 23.3189 18.1653C25.3382 18.1653 26.6873 18.6456 27.2999 19.0282C27.4179 19.103 27.5099 19.172 27.5703 19.2353C27.1878 19.6208 25.7294 20.3054 23.3189 20.3054C20.9085 20.3054 19.4472 19.6208 19.0647 19.2353C19.128 19.172 19.2171 19.103 19.3379 19.0282ZM27.6163 28.6154C27.205 29.0008 25.692 29.6653 23.3189 29.6653C20.9459 29.6653 19.4242 29.0066 19.0043 28.6154V25.7073C19.5249 25.9633 20.1663 26.1762 20.8912 26.3229H20.8941C21.6305 26.4725 22.4531 26.5559 23.3189 26.5559C24.1847 26.5559 24.993 26.4725 25.7236 26.3258C26.4485 26.1819 27.0928 25.9719 27.6163 25.7159V28.6154ZM27.6163 24.0677C27.205 24.4532 25.692 25.1176 23.3189 25.1176C20.9459 25.1176 19.4242 24.4589 19.0043 24.0677V20.895C19.7119 21.2459 20.641 21.5077 21.6966 21.6429C22.2115 21.7091 22.7551 21.7436 23.3189 21.7436C23.8827 21.7436 24.4264 21.7091 24.9384 21.6429C25.9854 21.5077 26.9087 21.2488 27.6163 20.9037V24.0677Z" fill="#3C329B" />
            <path d="M27.6165 25.7163V28.6158C27.2051 29.0012 25.6921 29.6657 23.3191 29.6657C20.946 29.6657 19.4244 29.007 19.0044 28.6158V25.7077C19.525 25.9637 20.1665 26.1766 20.8913 26.3233H20.8942C21.6306 26.4728 22.4533 26.5563 23.3191 26.5563C24.1849 26.5563 24.9932 26.4728 25.7238 26.3261C26.4486 26.1823 27.093 25.9723 27.6165 25.7163Z" fill="#BEBBFF" />
            <path d="M27.6165 20.9038V24.0679C27.2051 24.4533 25.6921 25.1178 23.3191 25.1178C20.946 25.1178 19.4244 24.4591 19.0044 24.0679V20.8951C19.712 21.2461 20.6411 21.5078 21.6967 21.643C22.2116 21.7092 22.7553 21.7437 23.3191 21.7437C23.8828 21.7437 24.4265 21.7092 24.9385 21.643C25.9855 21.5078 26.9089 21.2489 27.6165 20.9038Z" fill="#BEBBFF" />
        </svg>

    )
}

export function LifesavingData(props) {
    return (<svg width="33" height="37" viewBox="0 0 33 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M22.1552 16.052C21.3985 15.2952 20.3952 14.8803 19.326 14.8803C18.2568 14.8803 17.2535 15.2952 16.4968 16.052L15.9256 16.6256L15.3519 16.052C14.5952 15.2952 13.5919 14.8803 12.5227 14.8803C11.4535 14.8803 10.4502 15.2952 9.69345 16.052C8.93916 16.8063 8.52173 17.812 8.52173 18.8788C8.52173 19.9455 8.93916 20.9537 9.69345 21.708L15.9256 27.9401L21.5816 22.2817L22.1552 21.708C22.912 20.9537 23.327 19.948 23.327 18.8788C23.327 17.8096 22.912 16.8063 22.1552 16.052ZM21.2911 20.8463L20.7199 21.42L19.8557 22.2817L15.9256 26.2143L11.993 22.2817L11.1313 21.42L10.5576 20.8463C10.0303 20.319 9.74228 19.6233 9.74228 18.8788C9.74228 18.1342 10.0303 17.4385 10.5576 16.9137C11.0824 16.3889 11.7806 16.1008 12.5227 16.1008C13.2648 16.1008 13.9629 16.3889 14.4878 16.9137L15.9256 18.3515L16.7873 17.4874L17.3609 16.9137C17.8858 16.3889 18.5839 16.1008 19.326 16.1008C20.0681 16.1008 20.7663 16.3889 21.2911 16.9137C21.8184 17.4385 22.1064 18.1367 22.1064 18.8788C22.1064 19.6209 21.8184 20.319 21.2911 20.8463Z" fill="#3C329B" />
        <path d="M15.9252 3.77312L1.27869 16.5889V33.0662H30.5718V16.5889L15.9252 3.77312ZM29.3513 31.8457H2.49923V17.1405L15.9252 5.394L29.3513 17.1405V31.8457Z" fill="#3C329B" />
        <path d="M22.106 18.8786C22.106 19.6231 21.8179 20.3189 21.2906 20.8461L20.7194 21.4198L19.8553 22.2815L15.9251 26.2141L11.9925 22.2815L11.1308 21.4198L10.5571 20.8461C10.0299 20.3189 9.74182 19.6231 9.74182 18.8786C9.74182 18.1341 10.0299 17.4384 10.5571 16.9135C11.082 16.3887 11.7801 16.1006 12.5222 16.1006C13.2643 16.1006 13.9625 16.3887 14.4873 16.9135L15.9251 18.3513L16.7868 17.4872L17.3605 16.9135C17.8853 16.3887 18.5835 16.1006 19.3256 16.1006C20.0676 16.1006 20.7658 16.3887 21.2906 16.9135C21.8179 17.4384 22.106 18.1365 22.106 18.8786Z" fill="#BEBBFF" />
    </svg>
    )
}

export function VettedPartners(props) {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M29.3838 24.8448C28.6856 23.2752 27.1282 22.2646 25.4073 22.2646H21.4771V19.3963H24.1574C25.2876 19.3963 26.203 18.4785 26.203 17.3482V14.043H27.5798C27.9509 14.043 28.2755 13.8453 28.4293 13.5255C28.5709 13.2252 28.5319 12.8762 28.3219 12.615L26.203 9.97614V9.24382H26.1933C25.9907 5.11593 22.5756 1.82777 18.3964 1.82777C14.2173 1.82777 10.5874 5.32342 10.5874 9.63439C10.5874 11.536 11.2684 13.2814 12.4011 14.6362V22.2646H9.43274C7.71177 22.2646 6.15435 23.2752 5.4562 24.8448L1.797 33.0591H33.043L29.3838 24.8448ZM18.3964 3.04832C20.5544 3.04832 22.4755 4.09311 23.6765 5.70179H13.1163C14.3174 4.09311 16.2385 3.04832 18.3964 3.04832ZM11.8079 9.63439C11.8079 8.66772 12.0179 7.74987 12.3962 6.92234H24.3966C24.7213 7.63758 24.9215 8.42117 24.9679 9.24382C24.9752 9.29752 24.9776 9.35366 24.9752 9.40737C24.9801 9.48304 24.9825 9.55872 24.9825 9.63439C24.9825 9.8785 24.9703 10.1202 24.9434 10.357L25.9492 11.6092L26.203 11.9266L26.9232 12.8225H24.9825V17.3482C24.9825 17.8047 24.6139 18.1758 24.1574 18.1758H20.2565V25.0108H13.6217V14.1651C13.1188 13.6378 12.7038 13.0275 12.4011 12.3587C12.0203 11.5287 11.8079 10.6059 11.8079 9.63439ZM6.56933 25.3404C7.0722 24.2126 8.1951 23.4852 9.43274 23.4852H12.4011V26.2314H21.4771V23.4852H25.4073C26.6449 23.4852 27.7678 24.2126 28.2707 25.3404L31.1634 31.8386H3.67664L6.56933 25.3404Z" fill="#3C329B" />
            <path d="M31.1638 31.8385H3.67706L6.56976 25.3403C7.07262 24.2125 8.19553 23.4851 9.43316 23.4851H12.4015V26.2313H21.4775V23.4851H25.4077C26.6453 23.4851 27.7682 24.2125 28.2711 25.3403L31.1638 31.8385Z" fill="#BEBBFF" />
            <path d="M24.2037 29.8076C23.425 29.3243 22.988 28.5333 22.905 27.4544L22.866 26.9296H26.0296L26.015 27.4324C25.9808 28.5285 25.539 29.3316 24.7041 29.8149L24.4502 29.9614L24.2012 29.8076H24.2037Z" fill="white" />
            <path d="M25.5272 27.4177C25.4954 28.4283 25.0878 29.0288 24.4604 29.3925C23.8184 28.9946 23.462 28.338 23.3937 27.4177H25.5272ZM26.5329 26.4413H22.3391L22.4172 27.4909C22.5344 29.041 23.3131 29.8319 23.9454 30.2225L24.4433 30.5301L24.9486 30.2372C25.6297 29.8417 26.4548 29.0386 26.5036 27.447L26.5354 26.4413H26.5329Z" fill="#3C329B" />
            <path d="M11.53 6.8003L11.2542 2.98243L26.0618 1.63495L25.1659 6.8003H11.53Z" fill="#BEBBFF" />
            <path d="M28.2195 7.41044L26.0421 5.34284L26.0372 5.34772L26.5254 2.52337L26.7964 0.956192L25.2121 1.10022L11.7934 2.32076L10.5997 2.42817L10.6852 3.62431L10.878 6.27778L10.961 7.41044H25.6808H28.2195ZM11.9057 3.53643L25.3244 2.31588L24.6531 6.1899H12.0961L11.9033 3.53643H11.9057Z" fill="#3C329B" />
        </svg>

    )
}

export function SimpleAccessible(props) {
    return (
        <svg width="27" height="35" viewBox="0 0 27 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M22.2907 19.3581C21.0409 19.3581 17.6087 19.5656 15.4605 21.7137C14.8283 22.346 14.3645 23.0905 14.0252 23.857V19.7853C15.5093 19.734 18.485 19.3752 20.4233 17.4369C23.0889 14.7713 22.7692 10.1307 22.7692 10.1307C22.7692 10.1307 22.5958 10.1185 22.2956 10.1185C21.0457 10.1185 17.6136 10.326 15.4654 12.4742C14.323 13.6166 13.7298 15.1228 13.4247 16.4703C13.1195 15.1228 12.5263 13.6166 11.3839 12.4742C9.23574 10.326 5.80112 10.1185 4.55372 10.1185C4.25347 10.1185 4.08015 10.1307 4.08015 10.1307C4.08015 10.1307 3.76037 14.7713 6.42604 17.4369C8.35939 19.3703 11.3253 19.7316 12.8119 19.7853V23.8326C12.4726 23.0734 12.0137 22.3386 11.3863 21.7113C9.23818 19.5631 5.80356 19.3556 4.55616 19.3556C4.25591 19.3556 4.08259 19.3678 4.08259 19.3678C4.08259 19.3678 3.76281 24.0084 6.42848 26.674C8.36183 28.6074 11.3278 28.9687 12.8144 29.0224V33.0233H14.0349V29.0224C15.5191 28.9711 18.4948 28.6123 20.433 26.674C23.0987 24.0084 22.7789 19.3678 22.7789 19.3678C22.7789 19.3678 22.6056 19.3556 22.3054 19.3556L22.2907 19.3581ZM7.28042 25.8123C5.78647 24.3184 5.40078 21.992 5.30802 20.6055C6.65306 20.6982 9.01604 21.079 10.5149 22.5779C12.0088 24.0718 12.3945 26.3982 12.4873 27.7847C11.1422 27.692 8.77925 27.3112 7.28042 25.8123ZM16.3222 13.3408C17.8211 11.8419 20.184 11.4611 21.5291 11.3684C21.4363 12.7549 21.0506 15.0837 19.5567 16.5752C18.0627 18.0667 15.6973 18.4549 14.3523 18.5476C14.4475 17.1586 14.838 14.8225 16.3198 13.3408H16.3222ZM7.28042 16.5752C5.78647 15.0813 5.40078 12.7549 5.30802 11.3684C6.65306 11.4611 9.01604 11.8419 10.5149 13.3408C12.0088 14.8347 12.3945 17.1611 12.4873 18.5476C11.1422 18.4549 8.77925 18.0741 7.28042 16.5752ZM19.5591 25.8123C18.0627 27.3087 15.6998 27.692 14.3547 27.7847C14.4499 26.3933 14.8405 24.0596 16.3222 22.5779C17.804 21.0961 20.184 20.6982 21.5291 20.6055C21.4363 21.992 21.0506 24.3208 19.5567 25.8123H19.5591Z" fill="#3C329B" />
            <path d="M13.4074 1.81409C10.7124 1.81409 8.52521 4.00131 8.52521 6.69627C8.52521 9.39124 10.7124 11.5785 13.4074 11.5785C16.1024 11.5785 18.2896 9.39368 18.2896 6.69627C18.2896 3.99887 16.1048 1.81409 13.4074 1.81409ZM13.4074 10.3579C11.3886 10.3579 9.74575 8.71506 9.74575 6.69627C9.74575 4.67749 11.3886 3.03463 13.4074 3.03463C15.4262 3.03463 17.069 4.67749 17.069 6.69627C17.069 8.71506 15.4262 10.3579 13.4074 10.3579Z" fill="#3C329B" />
            <path d="M17.0697 6.69609C17.0697 8.71488 15.4268 10.3577 13.408 10.3577C11.3893 10.3577 9.7464 8.71488 9.7464 6.69609C9.7464 4.67731 11.3893 3.03445 13.408 3.03445C15.4268 3.03445 17.0697 4.67731 17.0697 6.69609Z" fill="#BEBBFF" />
            <path d="M5.6512 11.0249L4.96075 11.7153L12.1397 18.8942L12.8301 18.2038L5.6512 11.0249Z" fill="#3C329B" />
            <path d="M21.1876 11.0253L14.0087 18.2042L14.6991 18.8947L21.878 11.7157L21.1876 11.0253Z" fill="#3C329B" />
            <path d="M21.1853 20.26L14.0063 27.4389L14.6968 28.1293L21.8757 20.9504L21.1853 20.26Z" fill="#3C329B" />
            <path d="M5.65279 20.2612L4.96234 20.9516L12.1412 28.1305L12.8317 27.4401L5.65279 20.2612Z" fill="#3C329B" />
        </svg>

    )
}

export function ReliableData(props) {
    return (<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M45.4634 21.9267L37.1877 20.0051L32.224 21.0702V18.6648H25.5584V41.1393H32.224V35.7584C33.5421 37.5103 35.2217 38.7311 37.1905 39.6543C42.5352 37.2934 45.8388 33.0054 45.4634 21.9267ZM30.8336 39.7489H26.9488V20.0552H30.8336V21.3677L28.9148 21.7793C28.8676 26.9377 29.5489 30.6667 30.8336 33.4225V39.7489ZM42.2405 33.4114C41.1476 35.4914 39.582 36.9597 37.2072 38.1137C34.9548 36.9402 33.3864 35.3858 32.3019 33.264C32.2768 33.214 32.249 33.1611 32.224 33.1083C31.615 31.8764 31.1534 30.4332 30.8336 28.7647C30.5082 27.0573 30.3331 25.1107 30.308 22.9027L30.8336 22.7915L32.224 22.4912L37.1766 21.4317L44.098 23.0362C44.1537 27.5328 43.5447 30.9337 42.2405 33.4114Z" fill="#3C329B" />
        <path d="M28.9149 21.7793C28.8676 26.9377 29.5489 30.6667 30.8336 33.4225V39.7488H26.9488V20.0552H30.8336V21.3677L28.9149 21.7793Z" fill="#BEBBFF" />
        <path d="M44.098 23.0362L37.1766 21.4316L32.224 22.4911L30.8336 22.7915L30.308 22.9027C30.333 25.1106 30.5082 27.0572 30.8336 28.7646C31.1534 30.4331 31.615 31.8763 32.224 33.1082C32.249 33.1611 32.2768 33.2139 32.3019 33.264C33.3864 35.3857 34.9547 36.9402 37.2072 38.1137C39.582 36.9597 41.1476 35.4914 42.2404 33.4114C43.5446 30.9337 44.1536 27.5327 44.098 23.0362ZM37.1961 35.4191C34.2345 34.0287 32.4325 31.462 32.4909 25.2525L37.1961 24.243L41.9012 25.3359C42.1153 31.6372 40.2355 34.076 37.1961 35.4191Z" fill="white" />
        <path d="M41.9012 25.336L37.1961 24.2432L32.4909 25.2526C32.4325 31.4621 34.2345 34.0288 37.1961 35.4192C40.2355 34.0761 42.1153 31.6373 41.9012 25.336ZM37.2128 33.8648C35.5109 32.897 34.0454 31.1312 33.8925 26.3733L37.1822 25.6669L40.5275 26.4428C40.4997 30.9616 39.201 32.8025 37.2128 33.8648Z" fill="#3C329B" />
        <path d="M40.5275 26.4427C40.4997 30.9615 39.201 32.8024 37.2128 33.8647C35.5109 32.897 34.0454 31.1312 33.8925 26.3732L37.1822 25.6669L40.5275 26.4427Z" fill="#BEBBFF" />
        <path d="M17.736 24.4545V41.1393H24.4044V24.4545H17.736ZM23.014 39.7489H19.1264V25.8449H23.014V39.7489Z" fill="#3C329B" />
        <path d="M23.014 25.8448H19.1264V39.7489H23.014V25.8448Z" fill="#BEBBFF" />
        <path d="M9.95529 27.7914V41.1392H16.6209V27.7914H9.95529ZM15.2305 39.7488H11.3457V29.1818H15.2305V39.7488Z" fill="#3C329B" />
        <path d="M15.2305 29.1818H11.3457V39.7488H15.2305V29.1818Z" fill="#BEBBFF" />
        <path d="M9.75787 25.1997L10.1444 26.5345C17.5664 24.3933 24.5879 19.7994 31.0283 12.8891V15.973H32.4187V10.8285H27.2742V12.2189H29.7574C23.5618 18.7927 16.8406 23.1558 9.76065 25.1997H9.75787Z" fill="#3C329B" />
    </svg>
    )
}

export function ReducedFalseAlarms(props) {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M26.6762 21.707L19.1152 36.8235H34.2373L26.6762 21.707ZM26.6762 25.2275L31.6873 35.2468H21.6652L26.6735 25.2275H26.6762Z" fill="#3C329B" />
            <path d="M27.4632 28.5895H25.8893V32.1267H27.4632V28.5895Z" fill="#3C329B" />
            <path d="M27.4632 33.2334H25.8893V34.4875H27.4632V33.2334Z" fill="#3C329B" />
            <path d="M35.9197 37.8412C35.9058 37.8607 35.8918 37.8746 35.8752 37.8913C37.4936 35.8724 38.4641 33.3113 38.4641 30.5221C38.4641 24.2792 33.606 19.1681 27.4632 18.7621V16.9935H30.4554V12.8779H22.8944V16.9935H25.8865V18.7621C19.7465 19.1681 14.8884 24.2792 14.8884 30.5221C14.8884 33.3113 15.8561 35.8696 17.4718 37.8857C17.4579 37.869 17.4468 37.8579 17.4329 37.8412L15.4168 41.8289C15.008 42.6381 15.1582 43.5363 15.7727 43.9173L17.1047 44.746C17.2938 44.8655 17.5107 44.9212 17.7304 44.9212C18.2254 44.9212 18.7593 44.6403 19.1347 44.1397L21.4317 41.0836C21.3927 41.0642 21.3566 41.0419 21.3177 41.0197C22.925 41.8428 24.7408 42.3072 26.6707 42.3072C28.6006 42.3072 30.4248 41.84 32.0321 41.0169C31.9904 41.0391 31.9542 41.0642 31.9125 41.0836L34.2095 44.1397C34.5849 44.6403 35.116 44.9212 35.6138 44.9212C35.8335 44.9212 36.0476 44.8655 36.2395 44.746L37.5715 43.9173C38.1832 43.5363 38.3362 42.6381 37.9274 41.8289L35.9113 37.8412H35.9197ZM24.4711 15.4224V14.4574H28.8814V15.4224H24.4711ZM17.8806 43.1971C17.8416 43.2471 17.8055 43.2833 17.7721 43.3083L16.7738 42.6854C16.7794 42.6465 16.7961 42.5964 16.8239 42.5436L18.573 39.0842C18.5563 39.0676 18.5452 39.0537 18.5285 39.037C19.004 39.493 19.5185 39.9129 20.0663 40.2855L17.8806 43.1998V43.1971ZM26.6763 40.7388C21.0479 40.7388 16.4651 36.1588 16.4651 30.5249C16.4651 24.891 21.0451 20.311 26.6763 20.311C32.3074 20.311 36.8902 24.8938 36.8902 30.5249C36.8902 36.1561 32.3102 40.7388 26.6763 40.7388ZM35.5832 43.3083C35.5526 43.2833 35.5137 43.2471 35.4747 43.1971L33.289 40.2828C33.8396 39.9101 34.3541 39.4902 34.8324 39.0314C34.8157 39.0481 34.8018 39.0648 34.7823 39.0815L36.5314 42.5408C36.5592 42.5936 36.5759 42.6437 36.5815 42.6826L35.5832 43.3055V43.3083Z" fill="#3C329B" />
            <path d="M36.5815 42.6853L35.5832 43.3083C35.5526 43.2832 35.5136 43.2471 35.4747 43.197L33.289 40.2827C33.8396 39.9101 34.3541 39.4902 34.8323 39.0314C34.8157 39.0481 34.8018 39.0647 34.7823 39.0814L36.5314 42.5407C36.5592 42.5936 36.5759 42.6436 36.5815 42.6826V42.6853Z" fill="#BEBBFF" />
            <path d="M20.0691 40.2828L17.8834 43.1971C17.8444 43.2471 17.8083 43.2833 17.7749 43.3083L16.7766 42.6854C16.7822 42.6465 16.7989 42.5964 16.8267 42.5436L18.5758 39.0843C18.5591 39.0676 18.548 39.0537 18.5313 39.037C19.0068 39.493 19.5213 39.9129 20.0691 40.2856V40.2828Z" fill="#BEBBFF" />
            <path d="M28.8814 14.4574H24.4711V15.4223H28.8814V14.4574Z" fill="#BEBBFF" />
            <path d="M26.6763 20.311C21.0479 20.311 16.4651 24.8938 16.4651 30.5249C16.4651 36.1561 21.0451 40.7388 26.6763 40.7388C32.3074 40.7388 36.8902 36.1588 36.8902 30.5249C36.8902 24.891 32.3102 20.311 26.6763 20.311ZM19.1153 36.8207L26.6763 21.7042L34.2373 36.8207H19.1153Z" fill="white" />
            <path d="M26.6763 25.2274L21.668 35.2467H31.6901L26.679 25.2274H26.6763ZM27.466 34.4875H25.8921V33.2334H27.466V34.4875ZM25.8893 32.1266V28.5894H27.4632V32.1266H25.8893Z" fill="#BEBBFF" />
            <path d="M41.904 21.6486C41.8539 22.6191 41.5536 23.5618 41.0419 24.4044L34.1233 17.3857C35.041 16.8462 36.0726 16.5542 37.1015 16.5542C37.7717 16.5542 38.4141 16.6766 39.0008 16.9157C39.5876 17.1521 40.1187 17.5025 40.5636 17.9557C41.5063 18.9095 41.9818 20.2221 41.9068 21.6486H41.904Z" fill="#BEBBFF" />
            <path d="M41.6815 16.8517C40.4496 15.6003 38.7978 14.9802 37.0987 14.9802C36.156 14.9802 35.1994 15.1693 34.2873 15.5447C33.378 15.9229 32.5104 16.4846 31.754 17.2299L41.1587 26.7736C44.0452 23.9289 44.2788 19.4879 41.6815 16.8545V16.8517ZM41.0391 24.4072L34.1205 17.3884C35.0382 16.8489 36.0698 16.5569 37.0987 16.5569C37.7689 16.5569 38.4113 16.6793 38.998 16.9184C39.5848 17.1548 40.1159 17.5052 40.5608 17.9585C41.5035 18.9123 41.9791 20.2248 41.904 21.6514C41.8539 22.6219 41.5536 23.5646 41.0419 24.4072H41.0391Z" fill="#3C329B" />
            <path d="M16.2594 14.9802C14.5575 14.9802 12.9085 15.6003 11.6766 16.8517C9.07657 19.4851 9.31294 23.926 12.1994 26.7708L21.6041 17.2271C20.0886 15.7338 18.1448 14.9774 16.2622 14.9774L16.2594 14.9802ZM12.7973 17.9584C13.6844 17.0547 14.9163 16.5569 16.2594 16.5569C17.2855 16.5569 18.32 16.8489 19.2376 17.3884L12.319 24.4071C11.0315 22.277 11.1566 19.6241 12.7973 17.9584Z" fill="#3C329B" />
            <path d="M19.2376 17.3883L12.319 24.4071C11.0314 22.277 11.1566 19.6241 12.7973 17.9584C13.6843 17.0546 14.9162 16.5569 16.2594 16.5569C17.2855 16.5569 18.3199 16.8489 19.2376 17.3883Z" fill="#BEBBFF" />
            <path d="M27.466 6.58215H25.8921V10.3613H27.466V6.58215Z" fill="#3C329B" />
            <path d="M35.2798 8.85145L32.9252 11.8074L34.1563 12.788L36.5109 9.83211L35.2798 8.85145Z" fill="#3C329B" />
            <path d="M18.0765 8.85342L16.8452 9.83386L19.1993 12.7902L20.4306 11.8098L18.0765 8.85342Z" fill="#3C329B" />
        </svg>

    )
}

export function FreeIntegration(props) {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M11.2596 15.948C12.4525 15.948 13.4202 14.9775 13.4202 13.7873C13.4202 12.5971 12.4497 11.6266 11.2596 11.6266C10.0694 11.6266 9.09888 12.5971 9.09888 13.7873C9.09888 14.9775 10.0694 15.948 11.2596 15.948ZM11.2596 13.017C11.685 13.017 12.0298 13.3618 12.0298 13.7873C12.0298 14.2127 11.685 14.5576 11.2596 14.5576C10.8341 14.5576 10.4893 14.2127 10.4893 13.7873C10.4893 13.3618 10.8341 13.017 11.2596 13.017Z" fill="#3C329B" />
            <path d="M44.7293 8.4425H9.1489C7.30244 8.4425 5.80359 9.93858 5.80359 11.785V32.908C5.80359 34.7545 7.30244 36.2506 9.1489 36.2506H19.1654V39.582H15.592C14.4936 39.582 13.6037 40.4718 13.6037 41.5675V42.0485C13.6037 43.147 14.4936 44.0368 15.592 44.0368H38.3112C39.4096 44.0368 40.2995 43.147 40.2995 42.0485V41.5675C40.2995 40.4718 39.4096 39.582 38.3112 39.582H34.7379V36.2506H44.7293C46.5758 36.2506 48.0718 34.7545 48.0718 32.908V11.785C48.0718 9.93858 46.5758 8.4425 44.7293 8.4425ZM38.3112 40.9724C38.6421 40.9724 38.9091 41.2393 38.9091 41.5675V42.0485C38.9091 42.3795 38.6421 42.6464 38.3112 42.6464H15.592C15.2611 42.6464 14.9942 42.3795 14.9942 42.0485V41.5675C14.9942 41.2393 15.2611 40.9724 15.592 40.9724H38.3112ZM20.5558 39.5458V36.2506H33.3475V39.5458H20.5558ZM44.7293 34.8602H34.7379V34.8518H19.1654V34.8602H9.1489C8.08941 34.8602 7.22736 34.0148 7.19677 32.9636H46.6786C46.6508 34.0148 45.7888 34.8602 44.7293 34.8602ZM46.6814 31.5732H7.19399V11.785C7.19399 10.7089 8.06995 9.83291 9.1489 9.83291H44.7293C45.8055 9.83291 46.6814 10.7089 46.6814 11.785V31.5732Z" fill="#3C329B" />
            <path d="M38.9091 41.5675V42.0486C38.9091 42.3795 38.6421 42.6465 38.3112 42.6465H15.592C15.2611 42.6465 14.9941 42.3795 14.9941 42.0486V41.5675C14.9941 41.2394 15.2611 40.9724 15.592 40.9724H38.3112C38.6421 40.9724 38.9091 41.2394 38.9091 41.5675Z" fill="#BEBBFF" />
            <path d="M33.3475 36.2506H20.5558V39.5459H33.3475V36.2506Z" fill="#BEBBFF" />
            <path d="M46.6786 32.9636C46.6508 34.0148 45.7888 34.8601 44.7293 34.8601H34.7379V34.8518H19.1654V34.8601H9.1489C8.08942 34.8601 7.22737 34.0148 7.19678 32.9636H46.6786Z" fill="#BEBBFF" />
            <path d="M23.7258 25.2691L19.7548 21.2981L23.7342 17.3216L22.7498 16.34L17.7888 21.2981L22.7442 26.2535L23.7258 25.2691Z" fill="#3C329B" />
            <path d="M30.1661 17.3216L34.1427 21.2981L30.1745 25.2691L31.1561 26.2535L36.1087 21.2981L31.1505 16.34L30.1661 17.3216Z" fill="#3C329B" />
            <path d="M30.0189 12.266L22.7874 29.3522L24.0678 29.8941L31.2993 12.8079L30.0189 12.266Z" fill="#3C329B" />
        </svg>

    )
}

export function EasyIntegration(props) {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.3993 2.84348C10.9827 2.84348 11.5293 3.06997 11.9429 3.48356L14.6238 6.16452L5.92855 14.8598L3.24759 12.1788C2.83646 11.7677 2.60751 11.2187 2.60751 10.6352C2.60751 10.0518 2.834 9.50525 3.24759 9.09166L8.85815 3.4811C9.26928 3.06997 9.81827 2.84101 10.4017 2.84101L10.3993 2.84348ZM10.3993 1.61255C9.52531 1.61255 8.65136 1.9449 7.98666 2.61206L2.37609 8.22262C1.04423 9.55449 1.04423 11.716 2.37609 13.0503L5.92609 16.6003L16.3619 6.16452L12.8119 2.61452C12.1447 1.94736 11.2708 1.61501 10.3993 1.61501V1.61255Z" fill="#3C329B" />
            <path d="M1.88895 1.25631L1.01855 2.12671L5.49065 6.59881L6.36105 5.72841L1.88895 1.25631Z" fill="#3C329B" />
            <path d="M24.3847 14.1877L20.8347 10.6377L18.4369 13.0355L17.2872 14.1852L16.4157 15.0567L14.781 16.6939L13.9095 17.5629L12.8534 18.6215L11.9819 19.4905L10.3989 21.0735L13.9489 24.6235C14.6161 25.2906 15.4876 25.623 16.3615 25.623C17.2355 25.623 18.1094 25.2906 18.7766 24.6235L21.1449 22.2552L24.3847 19.0154C25.719 17.6811 25.719 15.5195 24.3847 14.1877ZM23.5157 18.1439L17.9051 23.7544C17.494 24.1656 16.945 24.3921 16.3615 24.3921C15.7781 24.3921 15.2315 24.1656 14.818 23.7544L12.1395 21.0735L12.8534 20.362L15.6501 17.5629L17.2872 15.9258L18.1562 15.0567L19.3059 13.907L20.1774 13.0355L20.8347 12.3782L23.5157 15.0567C24.365 15.9085 24.365 17.2921 23.5157 18.1439Z" fill="#3C329B" />
            <path d="M23.5159 18.1436L17.9053 23.7542C17.4942 24.1653 16.9452 24.3918 16.3617 24.3918C15.7783 24.3918 15.2317 24.1653 14.8181 23.7542L12.1396 21.0732L12.8536 20.3617L12.8757 20.3839L13.7448 19.5124L14.8034 18.4562L15.6724 17.5847L15.6503 17.5626L17.2874 15.9255L18.1564 15.0564L19.3061 13.9067L20.1776 13.0352L20.8349 12.3779L23.5159 15.0564C24.3652 15.9082 24.3652 17.2918 23.5159 18.1436Z" fill="#BEBBFF" />
            <path d="M21.2713 20.6408L20.4009 21.5112L24.873 25.9833L25.7434 25.1129L21.2713 20.6408Z" fill="#3C329B" />
            <path d="M11.0662 12.9788L10.1947 13.8478L9.13612 14.9064L8.26709 15.7754L9.13612 16.6469L11.982 19.4904L12.0042 19.5125L12.8535 20.3619L12.8757 20.384L13.7447 19.5125L14.8033 18.4564L15.6723 17.5849L11.0662 12.9788ZM10.0076 15.7754L11.0662 14.7193L13.9318 17.5849L12.8757 18.6435L10.0076 15.7754Z" fill="#3C329B" />
            <path d="M19.3064 12.1667L16.4851 9.34537L15.616 8.47388L14.7446 9.34537L13.5949 10.4951L12.7258 11.3641L13.5949 12.2356L16.4161 15.0569L17.2876 15.9259L18.1567 15.0569L19.3064 13.9072L20.1779 13.0357L19.3064 12.1667ZM17.2876 14.1854L14.4664 11.3641L15.616 10.2144L18.4373 13.0357L17.2876 14.1854Z" fill="#3C329B" />
            <path d="M12.9449 3.49162L3.25391 13.1826L4.1243 14.053L13.8153 4.36202L12.9449 3.49162Z" fill="#3C329B" />
        </svg>
    )
}

export function MonitoringProfessionals(props) {
    return (
        <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_572_502)">
                <path d="M20.9632 7.96327C19.399 6.40348 17.2453 5.43982 14.863 5.43982C12.4808 5.43982 10.3271 6.40348 8.76508 7.96327C7.20081 9.5253 6.23267 11.6835 6.23267 14.0702C6.23267 16.4569 7.20081 18.6173 8.76508 20.1771C10.3271 21.7369 12.4808 22.7006 14.863 22.7006C17.2453 22.7006 19.4012 21.7347 20.9655 20.1749C20.9655 20.1749 20.9662 20.1741 20.9677 20.1726C22.5298 18.6106 23.4957 16.4525 23.4957 14.0702C23.4957 11.6879 22.5275 9.52306 20.9632 7.96327ZM14.863 21.4254C10.8089 21.4254 7.51008 18.1288 7.51008 14.0724C7.51008 10.0161 10.8089 6.71947 14.863 6.71947C18.9171 6.71947 22.2182 10.0161 22.2182 14.0724C22.2182 18.1288 18.9194 21.4254 14.863 21.4254Z" fill="#3C329B" />
                <path d="M22.2182 14.0722C22.2182 18.1285 18.9193 21.4252 14.863 21.4252C10.8066 21.4252 7.51001 18.1285 7.51001 14.0722C7.51001 10.0159 10.8089 6.71924 14.863 6.71924C18.9171 6.71924 22.2182 10.0159 22.2182 14.0722Z" fill="#BEBBFF" />
                <path d="M14.8635 5.06555C6.41013 5.06555 -0.445312 14.0724 -0.445312 14.0724C-0.445312 14.0724 6.40789 23.0793 14.8635 23.0793C23.319 23.0793 30.1722 14.0724 30.1722 14.0724C30.1722 14.0724 23.3213 5.06555 14.8635 5.06555ZM20.9682 20.1749C20.9682 20.1749 20.9659 20.1749 20.9659 20.1771C19.1036 21.1363 17.0306 21.7997 14.8635 21.7997C12.6963 21.7997 10.6256 21.1363 8.7655 20.1794C5.20892 18.3506 2.42326 15.4485 1.21981 14.0724C2.42326 12.6942 5.20892 9.79422 8.7655 7.9655C10.6256 7.0108 12.6986 6.34521 14.8635 6.34521C17.0283 6.34521 19.1013 7.00856 20.9637 7.9655C24.5203 9.79646 27.3059 12.6964 28.5094 14.0724C27.3082 15.4507 24.5225 18.3462 20.9682 20.1749Z" fill="#3C329B" />
                <path d="M14.5678 17.6153C12.8646 16.562 11.9099 14.814 11.7306 12.4183L11.6858 11.8154H18.0146L17.9967 12.3936C17.9205 14.8454 16.9882 16.5575 15.1438 17.6221L14.8547 17.7901L14.5701 17.6131L14.5678 17.6153Z" fill="white" />
                <path d="M17.4363 12.3759C17.3601 14.8097 16.3762 16.2619 14.8635 17.1382C13.3149 16.179 12.4566 14.5923 12.2907 12.3759H17.4385M18.5926 11.2554H11.0806L11.1702 12.4588C11.3652 15.0428 12.4073 16.9365 14.2719 18.0906L14.8411 18.4425L15.4215 18.1063C17.4183 16.9499 18.4739 15.0338 18.5546 12.4095L18.5904 11.2554H18.5926Z" fill="#3C329B" />
                <path d="M4.27454 18.7337C2.82009 15.4192 2.82681 12.3601 4.29471 9.63721L5.28079 10.1683C3.96752 12.6044 3.97424 15.2601 5.30096 18.2833L4.27454 18.7337Z" fill="#3C329B" />
                <path d="M25.4771 18.7337L24.4507 18.2833C25.7774 15.2601 25.7841 12.6066 24.4709 10.1683L25.4569 9.63721C26.9226 12.3601 26.9316 15.4192 25.4771 18.7337Z" fill="#3C329B" />
            </g>
            <defs>
                <clipPath id="clip0_572_502">
                    <rect width="30" height="27.1429" fill="white" transform="translate(0 0.428589)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export function IntuitiveSystem(props) {
    return (<svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M16.0712 5.68298H15.1658V8.48062H16.0712V5.68298Z" fill="#3C329B" />
        <path d="M9.18853 7.33566L8.54321 7.9707L10.4903 9.94926L11.1356 9.31422L9.18853 7.33566Z" fill="#3C329B" />
        <path d="M22.3268 7.32445L20.3801 9.30334L21.0256 9.93827L22.9723 7.95938L22.3268 7.32445Z" fill="#3C329B" />
        <path d="M16.8426 13.1252V11.1497H14.4216V13.1252H12.4768V15.5734H14.4216V17.5489H16.8426V15.5734H18.7873V13.1252H16.8426ZM17.8819 14.668H15.9372V16.6435H15.327V14.668H13.3822V14.0306H15.327V12.055H15.9372V14.0306H17.8819V14.668Z" fill="#3C329B" />
        <path d="M27.211 3.51013H4.04226C2.83991 3.51013 1.86572 4.48432 1.86572 5.68667V19.4413C1.86572 20.6436 2.83991 21.6178 4.04226 21.6178H10.5646V23.7871H8.23781C7.52256 23.7871 6.94311 24.3665 6.94311 25.08V25.3932C6.94311 26.1085 7.52256 26.6879 8.23781 26.6879H23.0318C23.747 26.6879 24.3265 26.1085 24.3265 25.3932V25.08C24.3265 24.3665 23.747 23.7871 23.0318 23.7871H20.7049V21.6178H27.211C28.4134 21.6178 29.3894 20.6436 29.3894 19.4413V5.68667C29.3894 4.48432 28.4134 3.51013 27.211 3.51013ZM23.0318 24.6925C23.2472 24.6925 23.4211 24.8663 23.4211 25.08V25.3932C23.4211 25.6087 23.2472 25.7826 23.0318 25.7826H8.23781C8.02233 25.7826 7.84849 25.6087 7.84849 25.3932V25.08C7.84849 24.8663 8.02233 24.6925 8.23781 24.6925H23.0318ZM11.47 23.7636V21.6178H19.7996V23.7636H11.47ZM28.0983 20.3521C27.8701 20.5748 27.5551 20.7124 27.211 20.7124H20.7049V20.707H10.5646V20.7124H4.04226C3.35417 20.7124 2.79102 20.1619 2.77292 19.4775H28.4822C28.4731 19.8197 28.3283 20.1275 28.0983 20.3521ZM10.9648 18.525V14.5667C10.9648 11.999 13.0526 9.913 15.6185 9.913C18.1843 9.913 20.2722 11.999 20.2722 14.5667V18.525H10.9648ZM28.484 18.5721H21.1775V14.5667C21.1775 11.4956 18.6896 9.00762 15.6185 9.00762C12.5474 9.00762 10.0594 11.4956 10.0594 14.5667V18.5721H2.77111V5.68667C2.77111 4.98591 3.3415 4.41551 4.04226 4.41551H27.211C27.9136 4.41551 28.484 4.98591 28.484 5.68667V18.5721Z" fill="#3C329B" />
        <path d="M28.482 19.4773C28.4729 19.8195 28.3281 20.1274 28.0981 20.3519C27.8699 20.5746 27.5549 20.7122 27.2108 20.7122H20.7047V20.7068H10.5644V20.7122H4.04205C3.35396 20.7122 2.79081 20.1618 2.77271 19.4773H28.482Z" fill="white" />
        <path d="M23.4207 25.0799V25.3932C23.4207 25.6086 23.2469 25.7825 23.0314 25.7825H8.23746C8.02198 25.7825 7.84814 25.6086 7.84814 25.3932V25.0799C7.84814 24.8662 8.02198 24.6924 8.23746 24.6924H23.0314C23.2469 24.6924 23.4207 24.8662 23.4207 25.0799Z" fill="white" />
        <path d="M19.7993 21.6176H11.4697V23.7633H19.7993V21.6176Z" fill="#BEBBFF" />
        <path d="M15.619 9.91296C13.0531 9.91296 10.9653 11.999 10.9653 14.5666V18.525H20.2727V14.5666C20.2727 11.999 18.1849 9.91296 15.619 9.91296ZM18.7878 15.5734H16.8431V17.549H14.4221V15.5734H12.4773V13.1253H14.4221V11.1497H16.8431V13.1253H18.7878V15.5734Z" fill="#BEBBFF" />
        <path d="M5.49461 5.67578C4.7196 5.67578 4.08765 6.30593 4.08765 7.08275C4.08765 7.85957 4.7196 8.48971 5.49461 8.48971C6.26962 8.48971 6.90158 7.85775 6.90158 7.08275C6.90158 6.30774 6.27143 5.67578 5.49461 5.67578ZM5.49461 7.58433C5.21756 7.58433 4.99303 7.35798 4.99303 7.08275C4.99303 6.80751 5.21756 6.58116 5.49461 6.58116C5.77166 6.58116 5.99619 6.8057 5.99619 7.08275C5.99619 7.35979 5.77166 7.58433 5.49461 7.58433Z" fill="#3C329B" />
        <path d="M5.99608 7.08251C5.99608 7.35775 5.77155 7.5841 5.4945 7.5841C5.21746 7.5841 4.99292 7.35775 4.99292 7.08251C4.99292 6.80728 5.21746 6.58093 5.4945 6.58093C5.77155 6.58093 5.99608 6.80547 5.99608 7.08251Z" fill="#BEBBFF" />
    </svg>
    )
}

export function EstateAccess(props) {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M32.5899 13.3906L15.2988 28.5203V47.9728H30.9273V46.3102H16.9614V29.2751L32.5899 15.5986L48.2184 29.2751V46.3102H34.2525V47.9728H49.881V28.5203L32.5899 13.3906Z" fill="#3C329B" />
            <path d="M32.5899 25.3613C28.5497 25.3613 25.2744 28.6367 25.2744 32.6768C25.2744 34.9379 26.3019 36.963 27.9146 38.303C28.3303 38.6488 28.7825 38.9481 29.2647 39.1909V37.239C28.9122 36.9862 28.593 36.6936 28.3137 36.3644C27.4557 35.3768 26.937 34.0833 26.937 32.6768C26.937 29.5611 29.4741 27.0239 32.5899 27.0239C35.7056 27.0239 38.2427 29.5611 38.2427 32.6768C38.2427 34.0833 37.724 35.3768 36.8694 36.3644C36.5868 36.6936 36.2675 36.9896 35.9151 37.2423V39.1909C36.4006 38.9481 36.8528 38.6455 37.2684 38.2997C38.8812 36.9596 39.9053 34.9379 39.9053 32.6768C39.9053 28.6367 36.63 25.3613 32.5899 25.3613Z" fill="#3C329B" />
            <path d="M32.5898 29.4912C30.8308 29.4912 29.4043 30.9177 29.4043 32.6768C29.4043 34.4358 30.8308 35.8623 32.5898 35.8623C34.3489 35.8623 35.7787 34.4358 35.7787 32.6768C35.7787 30.9177 34.3522 29.4912 32.5898 29.4912ZM32.5898 34.1997C31.7519 34.1997 31.0669 33.518 31.0669 32.6768C31.0669 31.8355 31.7519 31.1538 32.5898 31.1538C33.4278 31.1538 34.1161 31.8355 34.1161 32.6768C34.1161 33.518 33.4311 34.1997 32.5898 34.1997Z" fill="#3C329B" />
            <path d="M34.2525 40.9897V51.9297L32.5899 53.5989L30.9273 51.9297V40.9897H29.2646V52.6147L32.5899 55.9532L35.9151 52.6147V40.9897H34.2525Z" fill="#3C329B" />
            <path d="M37.2689 38.2998L36.933 36.3645H36.8698C36.5872 36.6937 36.268 36.9896 35.9155 37.2424V40.2384L36.0452 40.9899H34.2529V42.6525H38.0204L37.2689 38.2998ZM29.1387 40.9899L29.2651 40.2583V37.239C28.9126 36.9863 28.5934 36.6937 28.3141 36.3645H28.2509L27.9151 38.3031L27.1636 42.6525H30.9277V40.9899H29.1387Z" fill="#3C329B" />
            <path d="M32.5899 27.0239C29.4741 27.0239 26.937 29.5611 26.937 32.6768C26.937 34.0833 27.4557 35.3768 28.3136 36.3644C28.593 36.6936 28.9122 36.9862 29.2647 37.239V40.2582L29.1383 40.9898H30.9273V51.9297L32.5899 53.599L34.2525 51.9297V40.9898H36.0447L35.9151 40.2383V37.2423C36.2675 36.9896 36.5868 36.6936 36.8694 36.3644C37.724 35.3768 38.2427 34.0833 38.2427 32.6768C38.2427 29.5611 35.7089 27.0239 32.5899 27.0239ZM32.5899 35.8623C30.8308 35.8623 29.4043 34.4358 29.4043 32.6768C29.4043 30.9177 30.8308 29.4912 32.5899 29.4912C34.3489 29.4912 35.7787 30.9177 35.7787 32.6768C35.7787 34.4358 34.3522 35.8623 32.5899 35.8623Z" fill="#BEBBFF" />
        </svg>

    )

}

export function VisitorManagement(props) {
    return (<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M52.611 27.1705L32.6597 10.9103L12.7085 27.1705V48.0728H17.7661L15.3687 53.4729H49.9508L47.55 48.0728H52.611V27.1705ZM17.9257 51.8103L19.585 48.0728L20.3265 46.4102L20.9351 45.0369C21.4405 43.8996 22.5711 43.1648 23.818 43.1648H27.3394V45.5323H36.9592V43.1648H41.4981C42.7451 43.1648 43.8756 43.8996 44.3811 45.0369L44.9896 46.4102L45.7311 48.0728L47.3904 51.8103H17.9257ZM27.5522 31.0909C27.2463 30.3328 27.08 29.5082 27.08 28.6436C27.08 24.9992 30.0461 22.0298 33.6939 22.0298C37.3416 22.0298 40.0816 24.7764 40.2911 28.2313L40.3975 28.883L41.6976 30.7584L41.9703 31.1508L42.4491 31.8391H40.311V36.2018C40.3077 36.8236 39.8023 37.3257 39.1805 37.3257H35.2966V43.8697H29.002V33.3022C28.3802 32.6771 27.8814 31.9256 27.5522 31.0909ZM50.9484 46.4102H46.8118L45.9007 44.3618C45.1259 42.6228 43.4035 41.5022 41.4981 41.5022H36.9592V38.9883H39.1805C40.72 38.9883 41.9703 37.7414 41.9703 36.2018V33.5017H43.4733C43.8956 33.5017 44.278 33.2723 44.4709 32.8965C44.667 32.5241 44.6404 32.0785 44.401 31.7327L41.9703 28.2313L41.9603 28.218C41.7375 23.8453 38.1197 20.3672 33.6939 20.3672C29.268 20.3672 25.4174 24.0748 25.4174 28.6436C25.4174 30.662 26.1423 32.5141 27.3394 33.9473V41.5022H23.818C21.9127 41.5022 20.1902 42.6228 19.4154 44.3618L18.5043 46.4102H14.3711V27.9586L32.6597 13.055L50.9484 27.9586V46.4102Z" fill="#3C329B" />
        <path d="M47.3899 51.8103H17.9253L19.5846 48.0728L20.3261 46.4102L20.9346 45.0369C21.44 43.8997 22.5706 43.1648 23.8176 43.1648H27.3389V45.5323H36.9588V43.1648H41.4977C42.7446 43.1648 43.8752 43.8997 44.3806 45.0369L44.9891 46.4102L45.7307 48.0728L47.3899 51.8103Z" fill="#BEBBFF" />
        <path d="M42.4492 31.8391H40.3111V36.2018C40.3077 36.8236 39.8023 37.3257 39.1805 37.3257H35.2967V43.8697H29.002V33.3022C28.3802 32.6771 27.8815 31.9256 27.5523 31.091C27.2463 30.3328 27.0801 29.5082 27.0801 28.6436C27.0801 24.9992 30.0462 22.0298 33.6939 22.0298C37.3417 22.0298 40.0816 24.7764 40.2911 28.2313L40.3975 28.883L41.6977 30.7585L41.9703 31.1508L42.4492 31.8391Z" fill="#BEBBFF" />
    </svg>
    )
}